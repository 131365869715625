import { Action } from "interfaces/context/Toast";
import { Toasts } from "interfaces/Toast";
import { idGenerator } from "utils/idGenerator";

const initialState: Toasts = {
    toastList: [],
};

const toastReducer = (
    state: Toasts,
    { type, payload: { toastList } }: Action
) => {
    switch (type) {
        case "clear_toast":
            return {
                ...state,
                ...initialState,
            };
        case "set_toast":
            return {
                ...state,
                toastList: [
                    ...state.toastList,
                    {
                        id: idGenerator(),
                        type: toastList[0].type,
                        message: toastList[0].message,
                        title: toastList[0].title,
                    },
                ],
            };
        case "delete_toast":
            return {
                ...state,
                toastList: state.toastList.filter(
                    (toast) => toast.id !== toastList[0].id
                ),
            };
        default:
            return state;
    }
};

export { toastReducer, initialState };
