import Facebook from "icons/Facebook";
import Google from "icons/Google";
import Instagram from "icons/Instagram";
import Twitter from "icons/Twitter";
import Youtube from "icons/Youtube";
import { BANKS } from "utils/constants";
const business_data = {
    contact: {
        number: "+58 412-0301536",
        email: "ventas1mauer@gmail.com",
        instagram: {
            label: "@Mauer.Ve",
            to: "https://www.instagram.com/mauer.ve/",
            icon: Instagram,
        },
        address: "Centro Ciudad Comercial Tamanaco (CCCT) Caracas, Nivel C2.",
    },
    social_media: [
        { link: "/", icon: Facebook },
        { link: "/", icon: Google },
        { link: "/", icon: Instagram },
        { link: "/", icon: Twitter },
        { link: "/", icon: Youtube },
    ],
    abstract:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor libero id et, in gravida. Sit diam duis mauris nulla cursus. Erat et lectus vel ut sollicitudin elit atamet.",
    zelle: "ubiecommerce@ubicutus.com",
    pago_movil: {
        bank: BANKS[0],
        juridic_person: "J",
        id: "123456789",
        phone_code: "0424",
        phone: "1234567",
    },
};

export { business_data };
