import { DispatchAction } from "contexts/createDataContext";
import { ExchangeRate } from "interfaces/context/ExchangeRate";

const setRate =
    (dispatch: DispatchAction<ExchangeRate>) =>
    ({ exchange_rate, id }) =>
        dispatch({ type: "set_rate", payload: { exchange_rate, id } });

const actions = { setRate };

export { actions };
