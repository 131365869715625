import { useContext, useEffect, useState } from "react";
import { Context as CategoriesContext } from "contexts/Categories/CategoriesContext";
import { useLocation } from "react-router-dom";
// import { useToast } from "./useToast";

export const useCategories = () => {
    const location = useLocation();
    const [loadingCategories, setLoadingCategories] = useState(true)
    const [categories, setCategories] = useState(null)
    // const { notify } = useToast()
    const {
        state: { categoriesList }, getCategories
    } = useContext(CategoriesContext);
    // const categoriesErrorNotification = () => notify("Ocurrió un error obteniendo la data de los Catálogos", "error")
    const fetchCategories = () => {
        getCategories(() => {});
    }
    useEffect(() => {
        if (categoriesList) {
            setCategories(categoriesList)
            setLoadingCategories(false)
        } else {
            setLoadingCategories(true)
        }
    }, [categoriesList, location])

    return {loadingCategories, setLoadingCategories, fetchCategories, categories}
}