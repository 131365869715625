import FlexBox from "containers/FlexBox";
import CheckoutNavLayout from "containers/Layout/CheckoutNavLayout";
import { Span } from "containers/Typography";
import { useContext } from "react";
import { Box, Button, Card, Divider, Grid } from "@mui/material";
import { Context as CartContext } from "contexts/Cart/CartContext";
import { useNavigate } from "react-router-dom";
import ProductCard9Cart from "components/ProductsCards/ProductCard9Cart";
import { NoIva } from "components/NoIva";
import { exchangeRatesList } from "data/ExchangeRates";
import { useExchangeRates } from "hooks/useExchangeRates";

const Cart = () => {
    const navigate = useNavigate();
    const {
        state: { products, total, subtotal },
    } = useContext(CartContext);
    const checkoutHandler = () => {
        navigate("/checkout");
    };
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];
    let subtotalsDecimals = (subtotal * exchange_rate).toFixed(2).split(".")[1]
    let totalsDecimals = ((total * exchange_rate)).toFixed(2).split(".")[1] || "00"
    return (
        <CheckoutNavLayout>
            <Grid container spacing={3}>
                <Grid item lg={8} md={8} xs={12}>
                    {products.length === 0 && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            width="100%"
                        >
                            <Span sx={{ textAlign: "center"}}>Tu carrito esta vacío. Comienza a comprar ahora.</Span>
                        </Box>
                    )}
                    {products.map((item) => (
                        <ProductCard9Cart key={item.id} product={item} />
                    ))}
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Card
                        sx={{
                            padding: "1.5rem 1.75rem",
                            "@media only screen and (max-width: 678px)": {
                                padding: "1rem",
                            },
                        }}
                    >
                        {total !== subtotal && (
                            <FlexBox
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}
                            >
                                <Span color="grey.500">Subtotal:</Span>
                                <FlexBox alignItems="flex-end">
                                    <Span
                                        fontSize="16px"
                                        fontWeight="400"
                                        lineHeight="1"
                                        color="secondary.main"
                                    >
                                        {`${symbol} `}
                                        {Math.trunc(subtotal * exchange_rate)},
                                    </Span>
                                    <Span
                                        fontWeight="400"
                                        fontSize="12px"
                                        lineHeight="1"
                                        color="secondary.main"
                                    >
                                        {subtotalsDecimals}
                                    </Span>
                                </FlexBox>
                            </FlexBox>
                        )}
                        <FlexBox
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                        >
                            <Span color="grey.600">Total:</Span>
                            <FlexBox alignItems="flex-end">
                                <Span
                                    fontSize="18px"
                                    fontWeight="600"
                                    lineHeight="1"
                                    color="secondary.main"
                                >
                                    {`${symbol} `}
                                    {Math.trunc(total * exchange_rate)},
                                </Span>
                                <Span
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="1"
                                    color="secondary.main"
                                >
                                    {totalsDecimals}
                                </Span>
                            </FlexBox>
                        </FlexBox>

                        <Divider
                            sx={{
                                mb: "1rem",
                            }}
                        />
                        <Box
                            mb={1}
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <NoIva />
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={products?.length === 0}
                            onClick={checkoutHandler}
                        >
                            Completar la compra
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </CheckoutNavLayout>
    );
};

export { Cart };
