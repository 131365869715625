import { Box, Grid } from "@mui/material";
import { CategoriesBanners } from "components/Categories/CategoriesBanners";
import CategoryList from "components/Categories/CategoryList";
import CategorySectionCreator from "containers/CategorySectionCreator";
import { useCategories } from "hooks/useCategories";
import React from "react";

export const CategoriesResume = () => {
    const { loadingCategories, categories } = useCategories();
    return (
        <>
            {loadingCategories || categories.length > 0  ? (
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <CategorySectionCreator>
                        <Grid container spacing={3} mb={1}>
                            <Grid item sm={3} xs={12}>
                                <CategoryList />
                            </Grid>
                            <Grid item sm={9} xs={12}>
                                <CategoriesBanners />
                            </Grid>
                        </Grid>
                    </CategorySectionCreator>
                </Box>
            ) : null}
        </>
    );
};
