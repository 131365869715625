import { Action, Category } from "interfaces/context/Categories";

const initialState: Category = {
  categoriesList: [],
};
const categoriesReducer = (state: Category, {type, payload}: Action) => {
  switch (type) {
    case "clear_categories":
      return {
        ...state,
        ...initialState,
      };
    case "get_categories":
      return {
        ...state,
        categoriesList: payload.categoriesList,
      };
    default:
      return state;
  }
};

export { categoriesReducer, initialState };
