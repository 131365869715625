import { Context as ToastContext } from "contexts/Toast/ToastContext";
import { useCallback, useContext } from "react";
import { SetToastDTO } from "interfaces/Toast";

export const useToast = () => {
    const {
        state: { toastList },
        setToast,
        deleteToast,
    } = useContext(ToastContext);

    const notify = useCallback(
        ({ message, type }: Omit<SetToastDTO, "title">) => {
            switch (type) {
                case "error":
                    setToast({
                        title: "Ha ocurrido un error",
                        message,
                        type,
                    });
                    break;
                case "success":
                    setToast({
                        title: "Operación exitosa",
                        message,
                        type,
                    });
                    break;
                case "info":
                    setToast({
                        title: "Te informamos",
                        message,
                        type,
                    });
                    break;
                case "warning":
                    setToast({
                        title: "Alerta",
                        message,
                        type,
                    });
                    break;
                default:
                    break;
            }
        },
        [setToast]
    );
    const closeToast = useCallback((id: string) => {
        deleteToast(id);
    }, [deleteToast]);
    return {
        toastList,
        notify,
        closeToast,
    };
};
