import BazarAvatar from "./BazarAvatar";
import BazarButton from "./BazarButton";
import BazarIconButton from "./BazarIconButton";
import FlexBox from "containers/FlexBox";
import ShoppingBagOutlined from "icons/ShoppingBagOutlined";
import { H5, Tiny } from "containers/Typography";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context as CartContext } from "contexts/Cart/CartContext";
import { SELECTED_ENVIRONTMENT } from "api/config";
import { NoIva } from "./NoIva";
import { exchangeRatesList } from "data/ExchangeRates";
import { useExchangeRates } from "hooks/useExchangeRates";
import { filterImagesBySize } from "utils/Images";
import { Size } from "interfaces/image";
// import { SELECTED_ENVIRONTMENT } from "api/config";

// const { resources } =  SELECTED_ENVIRONTMENT
const MiniCart = ({ toggleSidenav }) => {
    const { palette } = useTheme();
    const {
        state: { products, total },
        addProduct,
        subProduct,
        removeProduct,
    } = useContext(CartContext);
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];

    return (
        <Box width="380px">
            <Box
                overflow="auto"
                height={`calc(100vh - ${
                    !!products.length ? "80px - 4.25rem" : "0px"
                })`}
            >
                <FlexBox
                    alignItems="center"
                    m="0px 20px"
                    height="74px"
                    color="secondary.main"
                >
                    <ShoppingBagOutlined color="inherit" />
                    <Box fontWeight={600} fontSize="16px" ml={1}>
                        {products.length} item
                    </Box>
                </FlexBox>

                <Divider />

                {products.length === 0 ? (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100% - 74px)"
                    >
                        <Box
                            component="p"
                            mt={2}
                            color="grey.600"
                            textAlign="center"
                            maxWidth="200px"
                        >
                            Tu carrito esta vacío. Comienza a comprar ahora.
                        </Box>
                    </FlexBox>
                ) : (
                    products.map((item) => {
                        const image = filterImagesBySize(
                            item.images,
                            Size.Thumbnail
                        )[0].original;
                        const discount = !!parseFloat(item.discount_percentage)
                            ? parseFloat(item.discount_percentage)
                            : 0;
                        return (
                            <FlexBox
                                alignItems="center"
                                py={2}
                                px={2.5}
                                borderBottom={`1px solid ${palette.divider}`}
                                key={item.id}
                            >
                                <FlexBox
                                    alignItems="center"
                                    flexDirection="column"
                                >
                                    <BazarButton
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            height: "32px",
                                            width: "32px",
                                            borderRadius: "300px",
                                        }}
                                        onClick={() =>
                                            addProduct(products, item)
                                        }
                                    >
                                        <Add fontSize="small" />
                                    </BazarButton>
                                    <Box
                                        fontWeight={600}
                                        fontSize="15px"
                                        my="3px"
                                    >
                                        {item.quantity}
                                    </Box>
                                    <BazarButton
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            height: "32px",
                                            width: "32px",
                                            borderRadius: "300px",
                                        }}
                                        onClick={() =>
                                            subProduct(products, item)
                                        }
                                        disabled={item.quantity === 1}
                                    >
                                        <Remove fontSize="small" />
                                    </BazarButton>
                                </FlexBox>

                                <Link to={`/products/${item.id}`}>
                                    <BazarAvatar
                                        src={
                                            SELECTED_ENVIRONTMENT.resources +
                                            image
                                        }
                                        mx={2}
                                        alt={item.title}
                                        height={76}
                                        width={76}
                                    />
                                </Link>

                                <Box flex="1 1 0">
                                    <Link to={`/products/${item.id}`}>
                                        <H5 className="title" fontSize="14px">
                                            {item.title}
                                        </H5>
                                    </Link>
                                    <Tiny color="grey.600">
                                        {`${symbol} `}
                                        {(
                                            item.stockrecords[0].price *
                                            exchange_rate
                                        ).toFixed(2)}{" "}
                                        x {item.quantity}
                                    </Tiny>

                                    <Box
                                        fontWeight={600}
                                        fontSize="14px"
                                        color="primary.main"
                                        mt={0.5}
                                        display="flex"
                                    >
                                        {!!discount ? (
                                            <Box
                                                color="grey.600"
                                                fontWeight="600"
                                                mr={1}
                                            >
                                                <del>
                                                    {`${symbol} `}
                                                    {(
                                                        item.quantity *
                                                        item.stockrecords[0]
                                                            .price *
                                                        exchange_rate
                                                    )?.toFixed(2)}
                                                </del>
                                            </Box>
                                        ) : null}
                                        {`${symbol} `}
                                        {discount
                                            ? (
                                                  (item.quantity *
                                                      item.stockrecords[0]
                                                          .price -
                                                      (item.quantity *
                                                          item.stockrecords[0]
                                                              .price *
                                                          discount) /
                                                          100) *
                                                  exchange_rate
                                              ).toFixed(2)
                                            : (
                                                  item.quantity *
                                                  item.stockrecords[0].price *
                                                  exchange_rate
                                              )?.toFixed(2)}
                                    </Box>
                                </Box>

                                <BazarIconButton
                                    ml={2.5}
                                    size="small"
                                    onClick={() =>
                                        removeProduct(products, item)
                                    }
                                >
                                    <Close fontSize="small" />
                                </BazarIconButton>
                            </FlexBox>
                        );
                    })
                )}
            </Box>

            {!!products.length && (
                <Box p={2.5}>
                    <Box
                        mb={1}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <NoIva />
                    </Box>
                    <Link to="/checkout">
                        <BazarButton
                            variant="contained"
                            color="primary"
                            sx={{
                                mb: "0.75rem",
                                height: "40px",
                            }}
                            fullWidth
                            onClick={toggleSidenav}
                        >
                            Completar la compra ({`${symbol} `}
                            {(total * exchange_rate).toFixed(2)})
                        </BazarButton>
                    </Link>
                    <Link to="/cart">
                        <BazarButton
                            color="primary"
                            variant="outlined"
                            sx={{
                                height: 40,
                            }}
                            fullWidth
                            onClick={toggleSidenav}
                        >
                            Ver carrito
                        </BazarButton>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

MiniCart.defaultProps = {
    toggleSidenav: () => {},
};
export default MiniCart;
