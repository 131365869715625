/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Chip, Grid } from "@mui/material";
import BazarAvatar from "../BazarAvatar";
import { H1, H2, H3, H6, Span } from "containers/Typography";
import React, { useState, useEffect, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import FlexBox from "containers/FlexBox";
// import { SELECTED_ENVIRONTMENT } from "api/config";
import { TopRated } from "containers/HomeSections/TopRated";
import defaultProduct from "assets/images/productoDefault.png";
import { useCart } from "hooks/useCart";
import { strFirstLetterUpperCase } from "utils/utils";
import { SELECTED_ENVIRONTMENT } from "api/config";
import { NoIva } from "components/NoIva";
import { useExchangeRates } from "hooks/useExchangeRates";
import { exchangeRatesList } from "data/ExchangeRates";
import { filterImagesBySize } from "utils/Images";
import { Size } from "interfaces/image";
import Remove from "@mui/icons-material/Remove";
import Add from "@mui/icons-material/Add";

// const { resources } = SELECTED_ENVIRONTMENT

const ProductIntro = ({ product }) => {
    const { addToCart, removeInCart, subInCart, getCountOnCart } = useCart();
    const {
        title,
        description,
        images,
        stockrecords,
        categories,
        id,
        discount_percentage,
        // is_public,
    } = product;
    // console.log("product", product);
    const largeImages = filterImagesBySize(images, Size.Large);
    const mediumImages = filterImagesBySize(images, Size.Medium);
    const thumbnailImages = filterImagesBySize(images, Size.Thumbnail);
    let image, imgGroup;
    if (images.length > 0) {
        image = SELECTED_ENVIRONTMENT.resources + mediumImages[0].original;
        // image = SELECTED_ENVIRONTMENT.resources + ;
        imgGroup = thumbnailImages;
    }
    const colection = strFirstLetterUpperCase(categories);
    const price = stockrecords[0].price;
    // const available = !!is_public;
    const discount = !!parseFloat(discount_percentage)
        ? parseFloat(discount_percentage)
        : 0;
    const [imgToShow, setImgToShow] = useState({ src: image, ind: 0 });
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [imagesUrl, setImagesUrl] = useState([]);
    let quantity = getCountOnCart(id);

    useEffect(() => {
        if (largeImages.length > 0) {
            setImagesUrl(getUrlImageArray());
            // console.log(imagesUrl);
        }
    }, [images]);

    const getUrlImageArray = () => {
        let temp = [];
        largeImages.map((img, ind) => {
            temp = [...temp, SELECTED_ENVIRONTMENT.resources + img.original];
            return img;
        });
        return temp;
    };
    const handleImageClick = (ind) => () => {
        const newImg =
            SELECTED_ENVIRONTMENT.resources + mediumImages[ind].original;
        setImgToShow({ src: newImg, ind });
    };

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];

    return (
        <>
            <Box width="100%">
                <Grid container spacing={3} justifyContent="space-around">
                    <Grid item md={6} xs={12} alignItems="center">
                        <Box>
                            <FlexBox justifyContent="center" mb={6}>
                                {mediumImages.length > 0 ? (
                                    <img
                                        src={imgToShow.src}
                                        onClick={() => {
                                            openImageViewer(imgToShow.ind);
                                        }}
                                        alt={title}
                                        height={300}
                                        width={300}
                                        style={{
                                            height: 300,
                                            width: 300,
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <img
                                        src={defaultProduct}
                                        onClick={() => {
                                            openImageViewer(0);
                                        }}
                                        alt={title}
                                        height={300}
                                        width={300}
                                        style={{
                                            height: 300,
                                            width: 300,
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                                {isViewerOpen && (
                                    <ImageViewer
                                        src={imagesUrl}
                                        currentIndex={currentImage}
                                        onClose={closeImageViewer}
                                        backgroundStyle={{
                                            backgroundColor: "rgba(0,0,0,0.9)",
                                            zIndex: 10000,
                                        }}
                                        closeOnClickOutside={true}
                                    />
                                )}
                            </FlexBox>
                            <FlexBox overflow="auto">
                                {imgGroup.map((url, ind) => (
                                    <Box
                                        height={64}
                                        width={64}
                                        minWidth={64}
                                        bgcolor="white"
                                        borderRadius="10px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        border="1px solid"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        ml={ind === 0 ? "auto" : 0}
                                        mr={
                                            ind === imgGroup.length - 1
                                                ? "auto"
                                                : "10px"
                                        }
                                        borderColor={
                                            imgToShow.ind === ind
                                                ? "primary.main"
                                                : "grey.400"
                                        }
                                        onClick={handleImageClick(ind)}
                                        key={ind}
                                    >
                                        <BazarAvatar
                                            src={
                                                SELECTED_ENVIRONTMENT.resources +
                                                url.original
                                            }
                                            variant="square"
                                            height={40}
                                        />
                                    </Box>
                                ))}
                            </FlexBox>
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={12} alignItems="center">
                        <H1 mb={2}>{title}</H1>

                        <FlexBox alignItems="center" mb={2}>
                            <Box>Catálogo:</Box>
                            <H6 ml={1}>{colection}</H6>
                        </FlexBox>

                        <Box mb={3}>
                            <H2 color="secondary.main" mb={0.5} lineHeight="1">
                                {`${symbol} `}
                                {(price * exchange_rate).toFixed(2)}
                            </H2>
                            {discount ? (
                                <Box
                                    color="inherit"
                                    my={1}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 1,
                                    }}
                                >
                                    {discount ? (
                                        <Chip
                                            label={`Descuento: ${discount}%`}
                                            color="primary"
                                        />
                                    ) : null}
                                </Box>
                            ) : null}
                            <NoIva />
                        </Box>

                        <FlexBox alignItems="center" justifyContent="flext-start" mb={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={quantity === 0}
                                sx={{
                                    p: "5px",
                                }}
                                onClick={() => {
                                    if (quantity === 1) {
                                        removeInCart(product);
                                    } else {
                                        subInCart(product);
                                    }
                                }}
                            >
                                <Remove fontSize="small" />
                            </Button>
                            <Span mx={1} fontWeight="600" fontSize="15px">
                                {quantity}
                            </Span>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    p: "5px",
                                }}
                                onClick={() => addToCart(product)}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </FlexBox>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={4}>
                <H3 mb={2}>Descripción:</H3>
                <Box>{description}</Box>
            </Box>
            <TopRated />
        </>
    );
};

export default ProductIntro;
