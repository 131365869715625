import Footer from "./Footer";
import Header from "./Header";
import MobileNavigationBar from "components/MobileNavigationBar";
import Sticky from "../Sticky";
// import Topbar from "components/Topbar";
import React, { Fragment, useCallback, useState } from "react";
import { Toast } from "components/Toast";
import { Loader } from "components/Loader";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const AppLayout = ({
    children,
    navbar,
    title = "React Next.js Ecommerce Template",
}) => {
    const [isFixed, setIsFixed] = useState(false);
    const toggleIsFixed = useCallback((fixed) => {
        setIsFixed(fixed);
    }, []);
    return (
        <Fragment>
            <Fragment>
                <title>{title}</title>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width"
                />
            </Fragment>

            {/* <Topbar /> */}

            <Sticky fixedOn={0} onSticky={toggleIsFixed}>
                <Header isFixed={isFixed} />
            </Sticky>

            {navbar && <div className="section-after-sticky">{navbar}</div>}
            {!navbar ? (
                <div className="section-after-sticky">{children}</div>
            ) : (
                children
            )}
            <Toast position="bottom-left"/>
            <Loader />
            <MobileNavigationBar />
            <Footer />
        </Fragment>
    );
};

export default AppLayout;
