import { Context as LoaderContext } from "contexts/Loader/LoaderContext";
import { useContext } from "react";

export const useLoader = () => {
    const {
        loadedGlobal,
        loadingGlobal,
        state: { global },
    } = useContext(LoaderContext);

    return {
        loadingGlobal,
        loadedGlobal,
        global,
    };
};
