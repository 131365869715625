import { Backdrop, CircularProgress } from "@mui/material";
import { useLoader } from "hooks/useLoader";
import React from "react";

export const Loader = () => {
    const { local, global } = useLoader();
    return (
        <Backdrop open={local || global} sx={{ zIndex: 15000 }}>
            <CircularProgress />
        </Backdrop>
    );
};
