/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import React, { useCallback, useEffect, useState } from "react";
import CarouselCard, {
    CarouselCardSkeleton,
} from "components/Carousel/CarouselCard";
import Carousel from "components/Carousel/Carousel";
import Navbar from "components/Navbar";
import { Box } from "@mui/material";
import { useToast } from "hooks/useToast";
import { MainCarousel } from "api/resources/carousels";

const CarouselSection = () => {
    const [carouselData, setCarouselData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { notify } = useToast();
    const setData = useCallback(async () => {
        setLoading(true);
        const carousel = await MainCarousel.getAll();
        if (carousel.data) {
            setCarouselData(carousel.data);
        } else if (carousel.error) {
            notify({
                message:
                    "Ocurrio un error obteniendo la data de nuestro carrusel",
                type: "error",
            });
        }
        setLoading(false);
    }, []);
    useEffect(() => setData(), [setData]);
    return (
        <>
            {loading || carouselData.length ? (
                <>
                    <Navbar />
                    <Box bgcolor="white" mb={7.5} py={2}>
                        {/* <Container
                            sx={{
                                py: "2rem",
                            }}
                        > */}
                            <Carousel
                                totalSlides={
                                    carouselData.length > 0
                                        ? carouselData.length
                                        : 1
                                }
                                visibleSlides={1}
                                infinite={true}
                                autoPlay={true}
                                showDots={true}
                                showArrow={false}
                                spacing="0px"
                            >
                                {!loading ? (
                                    carouselData.map((data, ind) => (
                                        <CarouselCard
                                            carousel={data}
                                            key={ind}
                                        />
                                    ))
                                ) : (
                                    <CarouselCardSkeleton />
                                )}
                            </Carousel>
                        {/* </Container> */}
                    </Box>
                </>
            ) : null}
        </>
    );
};

export default CarouselSection;
