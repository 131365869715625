import BazarImage from "components/BazarImage";
import FlexBox from "containers/FlexBox";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { buttons } from "data/FieldsContent/404Fields";

const Error404 = () => {
    return (
        <FlexBox
            flexDirection="column"
            minHeight="100vh"
            justifyContent="center"
            alignItems="center"
            px={2}
        >
            <BazarImage
                src="https://cdni.iconscout.com/illustration/premium/thumb/404-error-page-2952323-2451625.png"
                sx={{
                    display: "block",
                    maxWidth: "320px",
                    width: "100%",
                    mb: "1.5rem",
                }}
            />
            <FlexBox flexWrap="wrap">
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        m: "0.5rem",
                    }}
                    // onClick={handleGoBack}
                >
                    {buttons.goBack}
                </Button>
                <Link to="/">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            m: "0.5rem",
                        }}
                    >
                        {buttons.goHome}
                    </Button>
                </Link>
            </FlexBox>
        </FlexBox>
    );
};

export { Error404 };
