import { DispatchAction } from "contexts/createDataContext";
import { SetToastDTO, ToastI, Toasts } from "interfaces/Toast";
import { idGenerator } from "utils/idGenerator";

const clearToast = (dispatch: DispatchAction<Toasts>) => () =>
    dispatch({ type: "clear_toast" });

const setToast =
    (dispatch: DispatchAction<Toasts>) => (newToast: SetToastDTO) => {
        const toastList = [{ ...newToast, id: idGenerator() }];
        dispatch({ type: "set_toast", payload: { toastList } });
    };

const deleteToast =
    (dispatch: DispatchAction<Toasts>) => (id: ToastI["id"]) => {
        const toastList: Toasts["toastList"] = [
            { title: "", type: "error", id, message: "" },
        ];
        dispatch({ type: "delete_toast", payload: { toastList } });
    };

const actions = { clearToast, setToast, deleteToast };

export { actions };
