import { Box, Grid, Skeleton } from "@mui/material";

import { H6 } from "containers/Typography";
import React, { Fragment } from "react";
import FlexBox from "containers/FlexBox";

const ProductIntroSkeleton = () => {
    return (
        <Fragment>
            <Box width="100%">
                <Grid container spacing={3} justifyContent="space-around">
                    <Grid item md={6} xs={12} alignItems="center">
                        <Box>
                            <FlexBox justifyContent="center" mb={6}>
                                <Skeleton
                                    variant="rectangular"
                                    width={300}
                                    height={300}
                                />
                            </FlexBox>
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={12} alignItems="center">
                        <Skeleton variant="text" />

                        <FlexBox alignItems="center" mb={2}>
                            <Box>Marca:</Box>
                            <H6 ml={1}>
                                <Skeleton variant="text" />
                            </H6>
                        </FlexBox>

                        <Box mb={3}>
                            <Skeleton variant="text" />
                            <Box color="inherit">
                                Disponibles: <Skeleton variant="text" />
                            </Box>
                        </Box>

                        <Skeleton
                            variant="rectangular"
                            width={180}
                            height={40}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                Descripción
                <Box>
                    <Skeleton variant="text" />
                </Box>
            </Box>
        </Fragment>
    );
};

export default ProductIntroSkeleton;
