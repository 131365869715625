import { Box, styled } from "@mui/material";
import { categoryBanners } from "data/category-banners";
import React from "react";
import { Link } from "react-router-dom";

const GridBox = styled(Box)(({ theme }) => ({
    display: "grid",

    gridTemplateAreas: `"banner1 banner2" 
    "banner3 banner4" 
    "banner3 banner5"`,
    gap: "10px",
    ".banner": {
        height: "100%",
    },
    "& .banner1": {
        gridArea: "banner1",
    },
    "& .banner2": {
        gridArea: "banner2",
    },
    "& .banner3": {
        gridArea: "banner3",
    },
    "& .banner4": {
        gridArea: "banner4",
    },
    "& .banner5": {
        gridArea: "banner5",
    },
    ".banner--img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        display: "block",
    },
    [theme.breakpoints.between("md", "xl")]: {
        gridTemplateRows: "repeat(3, 175px)",
    },
    [theme.breakpoints.down("md")]: {
        gridTemplateRows: "repeat(3, 143.5px)",
    },
}));

export const CategoriesBanners = () => {
    return (
        <GridBox>
            <figure className="banner1 banner">
                <Link to={`/products?is_new=True`}>
                    <img
                        src={categoryBanners[0].img}
                        className="banner--img"
                        alt="New products banner"
                    />
                </Link>
            </figure>
            <figure className="banner2 banner">
                <Link to={`/products?category=imagine&categoryLabel=imagine`}>
                    <img
                        src={categoryBanners[1].img}
                        className="banner--img"
                        alt="Imagine category banner"
                    />
                </Link>
            </figure>
            <figure className="banner3 banner">
                <Link to="/products?with_discount=True">
                    <img
                        src={categoryBanners[2].img}
                        className="banner--img"
                        alt="Offers banner"
                    />
                </Link>
            </figure>
            <figure className="banner4 banner">
                <Link to={`/products?category=silk-road&categoryLabel=silk%20road`}>
                    <img
                        src={categoryBanners[3].img}
                        className="banner--img"
                        alt="Silk category banner"
                    />
                </Link>
            </figure>
            <figure className="banner5 banner">
                <Link to="/products?best_selling=True">
                    <img
                        src={categoryBanners[4].img}
                        className="banner--img"
                        alt="Best selled banner"
                    />
                </Link>
            </figure>
        </GridBox>
    );
};
