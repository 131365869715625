import React from "react";
import MuiTheme from "theme/MuiTheme";
import { Router } from "routes/Router";
import { Provider as CategoriesProvider } from "contexts/Categories/CategoriesContext";
import { Provider as CartProvider } from "contexts/Cart/CartContext";
import { Provider as ToastProvider } from "contexts/Toast/ToastContext";
import { Provider as LoaderProvider } from "contexts/Loader/LoaderContext";
import { Provider as ExchangeRateProvider } from "contexts/ExchangeRates/ExchangeRateContext";
const App = () => {
    return (
        <CategoriesProvider>
            <ExchangeRateProvider>
            <CartProvider>
                <LoaderProvider>
                    <ToastProvider>
                        <MuiTheme>
                            <Router />
                        </MuiTheme>
                    </ToastProvider>
                </LoaderProvider>
            </CartProvider>
            </ExchangeRateProvider>
        </CategoriesProvider>
    );
};

export default App;
