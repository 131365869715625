import { Action } from "interfaces/context/Loader";
import { Loader } from "interfaces/Loader";

const initialState: Loader = {
    global: false,
};

const loaderReducer = (state: Loader, action: Action) => {
    switch (action.type) {
        case "loading_global":
            return {
                global: true,
            };
        case "loaded_global":
            return {
                global: false,
            };
        default:
            return state;
    }
};

export { loaderReducer, initialState };
