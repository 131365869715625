/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Context as CartContext } from "contexts/Cart/CartContext";
import { Error404 } from "pages/404";
import { Cart } from "pages/Cart";
import { Checkout } from "pages/Checkout";
import { Home } from "pages/Home";
import { OrderDetails } from "pages/OrderDetails";
import { ProductDetails } from "pages/ProductDetails";
import { RequiredCheckout as ReqCheckoutPage } from "pages/RequiredCheckout";
import { ProductList } from "pages/ProductList";
// import { RequiredCheckout } from "./RequiredCheckout";
import { useCategories } from "hooks/useCategories";
import { useExchangeRates } from "hooks/useExchangeRates";

export const Router = () => {
    const { fetchCategories } = useCategories();
    const { getCart } = useContext(CartContext);
    const { getLocalRate } = useExchangeRates();
    const updateLocalRate = async () => {
        await getLocalRate()
    }
    useEffect(() => {
        fetchCategories();
        updateLocalRate();
        getCart();
    }, []);
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            {/* <Route path="/checkout" element={<RequiredCheckout><Checkout /></RequiredCheckout>} /> */}
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/no_checkout" element={<ReqCheckoutPage />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/order" element={<OrderDetails />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
};
