import BazarCard from "../../BazarCard";
import { Skeleton, Stack, styled } from "@mui/material";
import React from "react";
import useWindowSize from "hooks/useWindowSize";

const StyledBazarCard = styled(BazarCard)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",
    "&:hover": {
        "& .css-1i2n18j": {
            display: "flex",
        },
    },
}));

export const ProductCard2Skeleton = () => {
    const width = useWindowSize();
    let imgFr
    if (width > 768) {
        imgFr = 4.75
    } else if (width <= 768 && width >= 425) {
        imgFr = 3.5
    } else {
        imgFr = 1.5
    }
    return (
        <StyledBazarCard>
            <Stack spacing={1}>
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={`calc(100vw / ${imgFr ? imgFr : 4})`}
                />
                    <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={50}
                />
            </Stack>
        </StyledBazarCard>
    );
};

