import * as yup from "yup";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { JURIDIC_PERSONS, MOBILE_PHONE_CODE } from "utils/constants";
import { validationMessages } from "settings/validations";
import { Grid, MenuItem, TextField } from "@mui/material";
const clientSchema = yup.object({
    name: yup.string().required(validationMessages.required),
    id_type: yup.string().required(validationMessages.required),
    id_value: yup
        .number()
        .integer(validationMessages.integer)
        .positive(validationMessages.positive)
        .min(9999, validationMessages.min(5))
        .required(validationMessages.required),
    guest_email: yup
        .string()
        .email(validationMessages.email)
        .required(validationMessages.required),
    phone_code: yup
        .number()
        .integer(validationMessages.integer)
        .positive(validationMessages.positive)
        .required(validationMessages.required),
    phone_number: yup
        .string()
        .matches(/^[0-9]+$/, validationMessages.integer)
});

const clientInitialValues = {
    name: "",
    id_type: "V",
    id_value: "",
    guest_email: "",
    phone_code: MOBILE_PHONE_CODE[0],
    phone_number: "",
};

export const ClientForm = ({ checkout }) => {
    const { handleChange, handleSubmit, errors, touched, values } = useFormik({
        initialValues: clientInitialValues,
        validationSchema: clientSchema,
        onSubmit: (values, { resetForm }) => {
            // console.log(values)
            try {
                if (values) {
                    checkout(values);
                }
            } catch (error) {
                // console.log(error.response.data.message);
            }
        },
    });

    return (
        <form onSubmit={handleSubmit} id="client-form">
            <Box mb={3.5}>
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            type="text"
                            label="Nombre y apellido"
                            name="name"
                            fullWidth
                            value={values.name}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid container item spacing={1} sm={6} xs={12}>
                        <Grid item xs={4}>
                            <TextField
                                type="text"
                                name="id_type"
                                fullWidth
                                select
                                value={values.id_type}
                                error={
                                    touched.id_type && Boolean(errors.id_type)
                                }
                                helperText={touched.id_type && errors.id_type}
                                onChange={handleChange}
                            >
                                {JURIDIC_PERSONS.map((item) => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                type="number"
                                label="Identificación"
                                name="id_value"
                                fullWidth
                                value={values.id_value}
                                error={
                                    touched.id_value && Boolean(errors.id_value)
                                }
                                helperText={touched.id_value && errors.id_value}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            type="text"
                            label="Email"
                            name="guest_email"
                            fullWidth
                            value={values.guest_email}
                            error={
                                touched.guest_email &&
                                Boolean(errors.guest_email)
                            }
                            helperText={
                                touched.guest_email && errors.guest_email
                            }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid container item spacing={1} sm={6} xs={12}>
                        <Grid item xs={4}>
                            <TextField
                                type="text"
                                name="phone_code"
                                fullWidth
                                select
                                value={values.phone_code}
                                error={
                                    touched.phone_code &&
                                    Boolean(errors.phone_code)
                                }
                                helperText={
                                    touched.phone_code && errors.phone_code
                                }
                                onChange={handleChange}
                            >
                                {MOBILE_PHONE_CODE.map((item) => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                type="number"
                                label="Número de teléfono"
                                name="phone_number"
                                fullWidth
                                value={values.phone_number}
                                error={
                                    touched.phone_number &&
                                    Boolean(errors.phone_number)
                                }
                                helperText={
                                    touched.phone_number && errors.phone_number
                                }
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
