import { SELECTED_ENVIRONTMENT } from "api/config";
import { differenceInMinutes } from "date-fns";
import ceil from "lodash/ceil";
export const getDateDifference = (date) => {
    let diff = differenceInMinutes(new Date(), new Date(date));
    if (diff < 60) return diff + " minutes ago";
    diff = ceil(diff / 60);
    if (diff < 24) return `${diff} hour${diff === 0 ? "" : "s"} ago`;
    diff = ceil(diff / 24);
    if (diff < 30) return `${diff} day${diff === 0 ? "" : "s"} ago`;
    diff = ceil(diff / 30);
    if (diff < 12) return `${diff} month${diff === 0 ? "" : "s"} ago`;
    diff = diff / 12;
    return `${diff.toFixed(1)} year${ceil(diff) === 0 ? "" : "s"} ago`;
};

const getParsedSearchAndQueryArray = (search) => {
    let parsedSearch;
    if (search[0] === "?") {
        parsedSearch = search.slice(1);
    } else {
        parsedSearch = search;
    }
    let querysArray = [];
    if (parsedSearch) {
        querysArray = parsedSearch.split("&");
    }
    return {
        parsedSearch,
        querysArray,
    };
};

export const updateQuery = (pathname, search, key, value) => {
    const { parsedSearch, querysArray } = getParsedSearchAndQueryArray(search);
    const newParam = key + "=" + value;
    const queryIncludesKey = (query) => query.includes(key);
    const keyInQuery =
        querysArray.length > 0 ? querysArray.some(queryIncludesKey) : false;
    if (keyInQuery) {
        const currentParam = querysArray.find(queryIncludesKey);
        const newQuerys = parsedSearch.replace(currentParam, newParam);
        return pathname + "?" + newQuerys;
    } else {
        if (parsedSearch) {
            return pathname + "?" + parsedSearch + "&" + newParam;
        } else {
            return pathname + "?" + newParam;
        }
    }
};

const joinWithAppersand = (arr) => {
    const strWithAppersand = arr.reduce((prev, curr) => prev + curr + "&", "");
    if (strWithAppersand.endsWith("&")) {
        return strWithAppersand.slice(0, strWithAppersand.length - 1);
    } else {
        return strWithAppersand;
    }
};

const delQuery = (key, querysArray) => {
    const excludeParam = (query) => query.split("=")[0] !== key;
    return querysArray.filter(excludeParam);
};

export const deleteQueryParam = (pathname, search, key) => {
    const { querysArray } = getParsedSearchAndQueryArray(search);
    let newQuerys;
    if (key === "price") {
        newQuerys = delQuery("price_high", delQuery("price_low", querysArray));
    } else if (key === "category") {
        newQuerys = delQuery(
            "category",
            delQuery("categoryLabel", querysArray)
        );
    } else {
        newQuerys = delQuery(key, querysArray);
    }
    return pathname + "?" + joinWithAppersand(newQuerys);
};

export const sortString = (a, b) => {
    const stringA = a.toUpperCase(); // ignore upper and lowercase
    const stringB = b.toUpperCase(); // ignore upper and lowercase
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }

    // names must be equal
    return 0;
};

export const strFirstLetterUpperCase = (str) => {
    const firstLetterUpperCase = str[0].toUpperCase();
    return str.replace(str[0], firstLetterUpperCase);
};

export const reduceTitle = (str, limit) => {
    if (typeof str === "string") {
        const title =
            str.length > limit ? str.slice(0, limit - 3) + "..." : str;
        return title;
    }
};

export const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};

export const navigateToUrl = (url) => {
    window.location.href = url
}
export const isIOS = () => {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
};

export const whatsappURI = ({ phone, text }) =>
    `https://wa.me/${phone}?text=${text}`;


    // ${
        // (state ||
        //     city ||
        //     municipality ||
        //     parish ||
        //     urbanization ||
        //     zip ||
        //     references) &&
        // `Ubicación de la entrega:
        // ${state && `Estado: ${state}\n`}${city && `Ciudad: ${city}\n`}${
        //     municipality && `Municipio: ${municipality}\n`
        // }${parish && `Parroquía: ${parish}\n`}${
        //     urbanization && `Urbanización: ${urbanization}\n`
        // }${zip && `Código zip: ${zip}\n`}${
        //     references && "Referencias: " + references
        // }`
    // } Tasa ${symbol}/$: *${exchange_rate}*\n
export const whatsappMessage = ({
    name,
    id,
    email,
    phone,
    // state,
    // city,
    // municipality,
    // parish,
    // urbanization,
    // zip,
    // references,
    payment_method,
    // delivery,
    products,
    subtotal,
    total,
    comments,
    orderNumber,
    // exchange_rate,
    symbol,
}) => `Hola, me gustaría concretar una orden

Datos del cliente:
    Nombre: ${name}
    Identificación: ${id}

Información de contacto:
    Teléfono: ${phone}    
    Email: ${email}

${payment_method && "Método de pago: " + payment_method + "\n"}
Mi orden [${orderNumber}] es:
\t${products.reduce(
    (
        prev,
        {
            quantity,
            title,
            stockrecords,
            brand,
            description,
            discount_percentage,
        }
    ) =>
        `${prev}${quantity}x ${brand} - ${title} - ${description} ${
            !!parseFloat(discount_percentage)
                ? `~${symbol}${stockrecords[0].price * quantity}~ ${symbol}${
                      stockrecords[0].price * quantity -
                      (
                          (stockrecords[0].price *
                              quantity *
                              parseFloat(discount_percentage)) /
                          100
                      ).toFixed(2)
                  }`
                : `${symbol}${stockrecords[0].price}`
        } \n\t`,
    ""
)}
\t${
    parseFloat(total.toFixed(2)) === parseFloat(subtotal.toFixed(2))
        ? `Total: ${symbol}${total.toFixed(2)}*`
        : `Subtotal: ${symbol}${subtotal.toFixed(
              2
          )}*\n\tTotal: $${total.toFixed(2)}*`
}\n

\n**Estos precios no incluyen impuestos* \n
${comments && "Comentarios: \n\t" + comments}`;

export const handlePath = (url) => {
    let anchor = false;
    let path = "";
    if (url.includes(`https://${SELECTED_ENVIRONTMENT.client}`)) {
        path = url.replace(`https://${SELECTED_ENVIRONTMENT.client}`, "");
        return {
            anchor,
            path,
        };
    } else if (url.includes(`http://${SELECTED_ENVIRONTMENT.client}`)) {
        path = url.replace(`http://${SELECTED_ENVIRONTMENT.client}`, "");
        return {
            anchor,
            path,
        };
    } else if (url.includes(`${SELECTED_ENVIRONTMENT.client}`)) {
        path = url.replace(`${SELECTED_ENVIRONTMENT.client}`, "");
        return {
            anchor,
            path,
        };
    } else {
        anchor = true;
        path = url;
        return {
            anchor,
            path,
        };
    }
};
