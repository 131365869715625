import imagineBanner from "assets/images/category-banners/imagine.png"
import newsBanner from "assets/images/category-banners/news.png"
import offersBanner from "assets/images/category-banners/offers.png"
import silkRoadBanner from "assets/images/category-banners/silk-road.png"
import topSalesBanner from "assets/images/category-banners/top-sales.png"

export const categoryBanners = [
    {
        img: newsBanner,
        to: "/"
    },
    {
        img: imagineBanner,
        to: "/"
    },
    {
        img: offersBanner,
        to: "/"
    },
    {
        img: silkRoadBanner,
        to: "/"
    },
    {
        img: topSalesBanner,
        to: "/"
    }
]