import CheckoutForm from "components/Checkout/CheckoutForm";
import CheckoutSummary from "components/Checkout/CheckoutSummary";
import AppLayout from "containers/Layout/AppLayout";
import { Container, Grid } from "@mui/material";
import React from "react";

const Checkout = () => {
    return (
        <AppLayout>
            <Container
                sx={{
                    my: "1.5rem",
                }}
            >
                <Grid container spacing={3}>
                    <Grid item lg={8} md={8} xs={12}>
                        <CheckoutForm />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CheckoutSummary />
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    );
};

export { Checkout };
