const logo =
    "https://www.ubicutus.com/static/home/img/elements/logo-ubicutus.svg";

const carouselData = [
    {
        title: "50% Off For Your First Shopping",
        photoUrl:
            "https://cdn.zeplin.io/6256e45e534c7424e4e53a13/assets/9F783F07-E7FF-4B15-A968-1DE4559D908C.svg",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convalliss.",
        buttonText: "Shop Now",
        buttonLik: "#",
    },
    {
        title: "50% Off For Your First Shopping",
        photoUrl:
            "https://images.pexels.com/photos/631988/pexels-photo-631988.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convalliss.",
        buttonText: "Shop Now",
        buttonLik: "#",
    },
];

const flashDeals = [
    {
        price: 250,
        title: "Smart watch silver",
        imgUrl: "https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 25,
        id: 4674163016,
        rating: 4,
        url: "",
    },
    {
        price: 350,
        title: "Smart watch blue",
        imgUrl: "https://images.pexels.com/photos/2113994/pexels-photo-2113994.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 15,
        id: 1786818620,
        rating: 3,
        url: "",
    },
    {
        price: 150,
        title: "Smart watch brown",
        imgUrl: "https://images.pexels.com/photos/1546333/pexels-photo-1546333.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 28,
        id: 5287174158,
        rating: 5,
        url: "",
    },
    {
        price: 180,
        title: "Smart watch",
        imgUrl: "https://images.pexels.com/photos/267394/pexels-photo-267394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 21,
        id: 6708885936,
        rating: 4,
        url: "",
    },
    {
        price: 250,
        title: "Smart watch blue red",
        imgUrl: "https://images.pexels.com/photos/47856/rolex-wrist-watch-clock-gmt-47856.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 25,
        id: 8287533170,
        rating: 4,
        url: "",
    },
    {
        price: 180,
        title: "Smart watch black",
        imgUrl: "https://images.pexels.com/photos/437037/pexels-photo-437037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 21,
        id: 9812971972,
        rating: 4,
        url: "",
    },
];

const categoryList = [
    {
        name: "Headphone",
        subtitle: "3k orders this week",
        categoryUrl: "/",
        image: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
        name: "Watch",
        subtitle: "3k orders this week",
        categoryUrl: "/",
        image: "https://images.pexels.com/photos/267394/pexels-photo-267394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
        name: "Sunglass",
        subtitle: "3k orders this week",
        categoryUrl: "/",
        image: "https://images.pexels.com/photos/343720/pexels-photo-343720.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
        name: "Shoes",
        subtitle: "3k orders this week",
        categoryUrl: "/",
        image: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
];

const featuredBrands = [
    {
        imgUrl: "https://images.pexels.com/photos/1437318/pexels-photo-1437318.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        title: "London Britches",
        productUrl: "/",
    },
    {
        imgUrl: "https://images.pexels.com/photos/988952/pexels-photo-988952.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        title: "Jim & Jago",
        productUrl: "/",
    },
];

const featuredProducts = [
    {
        price: 3300,
        reviewCount: 49,
        title: "Camera",
        imgUrl: "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 8,
        id: 2294256829,
        rating: 4,
        imgGroup: [
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        ],
        url: "",
    },
    {
        price: 400,
        reviewCount: 20,
        title: "Shoe",
        imgUrl: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 5,
        id: 4295570149,
        rating: 4.75,
        imgGroup: [
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        ],
        url: "",
    },
    {
        price: 999,
        reviewCount: 65,
        title: "Phone",
        imgUrl: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 5,
        id: 5676950804,
        rating: 5,
        imgGroup: [
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        ],
        url: "",
    },
    {
        price: 999,
        reviewCount: 75,
        title: "Watch",
        imgUrl: "https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 6,
        id: 1674789677,
        rating: 5,
        imgGroup: [
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/631986/pexels-photo-631986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        ],
        url: "",
    },
];

const newArrivals = [
    {
        price: 150,
        title: "Sunglass",
        imgUrl: "https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 8,
        id: 1670078035,
        rating: 5,
        url: "",
    },
    {
        price: 250,
        title: "Makeup",
        imgUrl: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 8,
        id: 3302401429,
        rating: 4,
        url: "",
    },
    {
        price: 350,
        title: "Smart Watch",
        imgUrl: "https://images.pexels.com/photos/267394/pexels-photo-267394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 10,
        id: 814272833,
        rating: 5,
        url: "",
    },
    {
        price: 15,
        title: "Lipstick",
        imgUrl: "https://images.pexels.com/photos/437037/pexels-photo-437037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        discount: 5,
        id: 8169421085,
        rating: 4,
        url: "",
    },
];

const cartData = [
    {
        price_incl_tax: 250.50,
        name: "Ford 2019",
        imgUrl: "https://images.pexels.com/photos/437037/pexels-photo-437037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        id: "7222243834583537",
        quantity: 1,
    },
    {
        price_incl_tax: 250,
        name: "Porsche 2020",
        imgUrl: "https://images.pexels.com/photos/267394/pexels-photo-267394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        id: "38553442244076086",
        quantity: 1,
    },
    {
        price_incl_tax: 250,
        name: "Heavy 20kt Gold Necklace",
        imgUrl: "https://images.pexels.com/photos/815494/pexels-photo-815494.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        id: "9573201630529315",
        quantity: 1,
    },
];

export {
    carouselData,
    flashDeals,
    categoryList,
    featuredBrands,
    featuredProducts,
    newArrivals,
    logo,
    cartData,
};
