import ArrowRight from "@mui/icons-material/ArrowRight";
import React from "react";
import { Link } from "react-router-dom";
import FlexBox from "./FlexBox";
import { H2, H5 } from "./Typography";

const CategorySectionHeader = ({ title, seeMoreLink, icon, subtitle }) => {
    return (
        <>
            <FlexBox
                justifyContent="space-between"
                alignItems="center"
                mb={subtitle ? 1.5 : 3}
            >
                <FlexBox alignItems="center">
                    {icon && (
                        <FlexBox mr={1} alignItems="center">
                            {icon}
                        </FlexBox>
                    )}
                    <H2 fontWeight="bold" lineHeight="1">
                        {title}
                    </H2>
                </FlexBox>

                {seeMoreLink && (
                    <Link to={seeMoreLink}>
                        <FlexBox alignItems="center" ml={1} color="grey.600">
                            Ver todos
                            <ArrowRight fontSize="small" color="inherit" />
                        </FlexBox>
                    </Link>
                )}
            </FlexBox>
            {subtitle && (
                <H5 fontWeight="light" lineHeight="1" mb={3}>
                    {subtitle}
                </H5>
            )}
        </>
    );
};

export default CategorySectionHeader;
