import { Box, styled } from "@mui/material";
import React from "react";
import CategoryMenuItem from "./CategoryMenuItem";
import { useCategories } from "hooks/useCategories";
import { strFirstLetterUpperCase } from "utils/utils";
import MegaMenu1 from "./mega-menu/MegaMenu1";
// import MegaMenu1 from "./mega-menu/MegaMenu1";
// import MegaMenu2 from "./mega-menu/MegaMenu2"; // component props interface

// styled component
const Wrapper = styled(Box)(({ theme, position, open }) => ({
    position: position || "unset",
    padding: "0.5rem 0px",
    left: 0,
    right: "auto",
    top: position === "absolute" ? "calc(100% + 0.7rem)" : "0.5rem",
    borderRadius: 4,
    transform: open ? "scaleY(1)" : "scaleY(0)",
    transformOrigin: "top",
    backgroundColor: "rgba(255, 255, 255)",
    boxShadow: theme.shadows[2],
    transition: "all 250ms ease-in-out",
    zIndex: 98,
}));

const CategoryMenuCard = ({ open, position }) => {
    const { categories } = useCategories();

    return (
        <Wrapper open={open} position={position}>
            {categories
                ? categories.length > 0 &&
                  categories.map(({ name, slug, subcategories }, ind) => {
                      return (
                          <CategoryMenuItem
                              title={strFirstLetterUpperCase(name)}
                              href={`/products?category=${slug}&categoryLabel=${name}`}
                              key={ind}
                              caret={subcategories?.length > 0}
                          >
                              {subcategories?.length > 0 ? (
                                  <MegaMenu1 categories={subcategories} />
                              ) : null}
                          </CategoryMenuItem>
                      );
                  })
                : null}
        </Wrapper>
    );
};

CategoryMenuCard.defaultProps = {
    position: "absolute",
};
export default CategoryMenuCard;
