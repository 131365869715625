import { DispatchAction } from "contexts/createDataContext";
import { Loader } from "interfaces/Loader";

const loadingGlobal = (dispatch: DispatchAction<Loader>) => () =>
    dispatch({ type: "loading_global" });

const loadedGlobal = (dispatch: DispatchAction<Loader>) => () =>
    dispatch({ type: "loaded_global" });

const actions = {loadedGlobal, loadingGlobal };

export { actions };
