/* eslint-disable react-hooks/exhaustive-deps */

import FlexBox from "containers/FlexBox";
import NavbarLayout from "containers/Layout/NavbarLayout";
import ProductCard9List from "components/Products/ProductCard9List";
import ProductFilterCard from "components/Products/ProductFilterCard";
import Sidenav from "containers/Sidenav";
import { H5, Paragraph } from "containers/Typography";
import useWindowSize from "hooks/useWindowSize";
import Apps from "@mui/icons-material/Apps";
import FilterList from "@mui/icons-material/FilterList";
import ViewList from "@mui/icons-material/ViewList";
import {
    Card,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "hooks/useQuery";
import { Products } from "api/resources/products";
import { useLocation, useNavigate } from "react-router-dom";
import ProductCardListContainer from "components/Products/ProductCardListContainer";
import {
    deleteQueryParam,
    sortString,
    strFirstLetterUpperCase,
    updateQuery,
} from "utils/utils";
import { useToast } from "hooks/useToast";
import ProductCard2List from "components/Products/ProductCard2List";

const PAGE_INTERVAL = 12;

const ProductList = () => {
    const width = useWindowSize();
    const isTablet = width < 960;
    const toggleView = useCallback(
        (v) => () => {
            setView(v);
        },
        []
    );
    const [pagination, setPagination] = useState({
        low: 0,
        high: PAGE_INTERVAL,
        // page: page ? parseInt(page) : 1,
        page: 1,
    });
    const [lastScrollY, setLastScrollY] = useState(0);
    const navigate = useNavigate();
    const { notify } = useToast();
    let query = useQuery();
    let category = query.get("category") || null;
    let categoryLabel = query.get("categoryLabel") || null;
    let categoryChipLabel = null;
    if (categoryLabel) {
        categoryChipLabel = strFirstLetterUpperCase(categoryLabel);
    }
    let title = query.get("title") || null;
    let sort = query.get("sort") || null;
    let is_new = query.get("is_new") || null;
    let with_discount = query.get("with_discount") || null;
    let best_selling = query.get("best_selling") || null;
    let best_prices = query.get("best_prices") || null;
    // let page = query.get("page") || null;
    let price_low = query.get("price_low") || null;
    let price_high = query.get("price_high") || null;
    let price_between;
    let priceChipLabel;
    let priceChipValue;
    if (price_low && !price_high) {
        price_between = `${price_low}`;
        priceChipLabel = `Mayor a:`;
        priceChipValue = `${price_low}`;
    } else if (!price_low && price_high) {
        price_between = `_,${price_high}`;
        priceChipLabel = `Menor a:`;
        priceChipValue = `${price_high}`;
    } else if (price_low && price_high) {
        price_between = `${price_low},${price_high}`;
        priceChipLabel = `Entre:`;
        priceChipValue = `${price_low} y ${price_high}`;
    } else {
        price_between = null;
        priceChipLabel = null;
        priceChipValue = null;
    }

    // let params = {
    //     title,
    //     category,
    //     price_between,
    //     sort,
    //     is_new,
    //     // page,
    //     page: pagination.page || 1,
    //     with_discount,
    //     best_prices,
    //     best_selling,
    // };

    const filterParams = [
        {
            key: "category",
            label: "Categoría:",
            value: categoryChipLabel,
        },
        {
            key: "price",
            label: priceChipLabel,
            value: priceChipValue,
        },
        {
            key: "is_new",
            label: "Nuevo",
            value: is_new === "True" ? "" : null,
        },
        {
            key: "with_discount",
            label: "Ofertas",
            value: with_discount === "True" ? "" : null,
        },
        {
            key: "best_selling",
            label: "Mejores vendidos",
            value: best_selling === "True" ? "" : null,
        },
        {
            key: "best_prices",
            label: "Mejores precios",
            value: best_prices === "True" ? "" : null,
        },
    ];

    const { search, pathname } = useLocation();
    const [location, setLocation] = useState(search);

    const [products, setProducts] = useState({
        products: [],
        results: 0,
    });
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [view, setView] = useState("grid");
    const [sortOption, setSortOption] = useState("");

    // const getProducts = async () => {
    //     setLoadingProducts(true);
    //     await Products.getAll({ params }).then((res) => {
    //         if (res.error) {
    //             notify({
    //                 message:
    //                     "Ocurrió un error obteniendo la data de los productos",
    //                 type: "error",
    //             });
    //         } else {
    //             setProducts({
    //                 products: res.data.results,
    //                 results: res.data.count,
    //             });
    //         }
    //     });
    //     setLoadingProducts(false);
    // };

    const getProducts = async (value) => {
        let params = {
            title,
            category,
            price_between,
            sort,
            is_new,
            page: value,
            with_discount,
            best_prices,
            best_selling,
        };
        setLoadingProducts(true);
        if (value === 1) {
            await Products.getAll({ params }).then((res) => {
                if (res.error) {
                    notify({
                        message:
                            "Ocurrió un error obteniendo la data de los productos",
                        type: "error",
                    });
                } else {
                    setProducts({
                        products: res.data.results,
                        results: res.data.count,
                    });
                }
            });
        } else {
            await Products.getAll({ params }).then((res) => {
                if (res.error) {
                    notify({
                        message:
                            "Ocurrió un error obteniendo la data de los productos",
                        type: "error",
                    });
                } else {
                    setProducts({
                        products: [...products.products, ...res.data.results],
                        results: res.data.count,
                    });
                }
            });
        }
        setLoadingProducts(false);
    };

    const toggleSortOptionChange = (e) => {
        const value = e.target.value;
        setSortOption(value);
        toggleSortingProducts(value);
    };

    const toggleSortingProducts = (value) => {
        const productsCopy = [...products.products];
        if (value === "asc_price") {
            const sortedPriceAsc = productsCopy.sort(
                (a, b) => a.stockrecords[0].price - b.stockrecords[0].price
            );
            setLocation(
                updateQuery(pathname, search, "sort", "stockrecords__price")
            );
            setProducts({
                products: sortedPriceAsc,
                results: sortedPriceAsc.length,
            });
        } else if (value === "desc_price") {
            const sortedPriceDesc = productsCopy.sort(
                (a, b) => b.stockrecords[0].price - a.stockrecords[0].price
            );
            setLocation(
                updateQuery(pathname, search, "sort", "-stockrecords__price")
            );
            setProducts({
                products: sortedPriceDesc,
                results: sortedPriceDesc.length,
            });
        } else if (value === "asc_name") {
            const sortedNameAsc = productsCopy.sort((a, b) =>
                sortString(a.title, b.title)
            );
            setLocation(updateQuery(pathname, search, "sort", "title"));
            setProducts({
                products: sortedNameAsc,
                results: sortedNameAsc.length,
            });
        } else if (value === "desc_name") {
            const sortedNameDesc = productsCopy.sort((a, b) =>
                sortString(b.title, a.title)
            );
            setLocation(updateQuery(pathname, search, "sort", "title"));
            setProducts({
                products: sortedNameDesc,
                results: sortedNameDesc.length,
            });
        }
    };
    // const handlePaginationChange = (e, value) => {
    //     const indexValue = value - 1;
    //     setPagination({
    //         low: indexValue * PAGE_INTERVAL,
    //         high: value * PAGE_INTERVAL - 1,
    //         page: value,
    //     });
    //     setLocation(updateQuery(pathname, search, "page", value));
    // };

    const handlePaginationChange = (value) => {

        setLastScrollY(window.scrollY)

        setPagination({
            ...pagination,
            page: value,
        });
    };

    const handleParamDeletion = (key) => {
        setLocation(deleteQueryParam(pathname, search, key));
    };

    useEffect(() => {
        getProducts(1);
        setPagination({
            ...pagination,
            page: 1,
        });
    }, [search]);

    useEffect(() => {
        if (pagination.page !== 1) {
            getProducts(pagination.page);
        }
    }, [pagination.page]);

    //This was added to prevent automatically scrolling to the top in Safari when clicking 'ver más'
    useEffect(() => {
        window.scrollTo(0, lastScrollY);
    });

    useEffect(() => {
        navigate(location);
    }, [location]);

    return (
        <NavbarLayout>
            <Box pt={2.5}>
                <Card
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "55px",
                        p: {
                            xs: "1.25rem 1.25rem 0.25rem",
                            sm: "1rem 1.25rem",
                            md: "0.5rem 1.25rem",
                        },
                    }}
                    elevation={1}
                >
                    <div>
                        {title && <H5>Buscando por “ {title} ”</H5>}
                        <Paragraph color="grey.600">
                            {products.results} resultados encontrados
                        </Paragraph>
                        {filterParams.map((filter) => {
                            if (filter.value !== null) {
                                return (
                                    <Chip
                                        sx={{
                                            margin: "1rem 0 1rem 0.5rem",
                                        }}
                                        label={`${filter.label} ${filter.value}`}
                                        key={filter.key}
                                        onDelete={() =>
                                            handleParamDeletion(filter.key)
                                        }
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                    <FlexBox alignItems="center" flexWrap="wrap" my="1rem">
                        <FlexBox flex="1 1 0" mr={1}>
                            <FormControl fullWidth>
                                <InputLabel sx={{ top: "-20%" }}>
                                    Ordenar por
                                </InputLabel>
                                <Select
                                    variant="outlined"
                                    size="small"
                                    defaultValue={"Ordenar por"}
                                    value={sortOption}
                                    onChange={toggleSortOptionChange}
                                    sx={{
                                        flex: "1 1 0",
                                        minWidth: "150px",
                                    }}
                                >
                                    {sortOptions.map((item) => (
                                        <MenuItem
                                            value={item.value}
                                            key={item.value}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FlexBox>

                        <FlexBox my="0.25rem">
                            <Box
                                alignItems="center"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "flex",
                                    },
                                }}
                            >
                                <Paragraph color="grey.600" mr={1}>
                                    Vista:
                                </Paragraph>
                                <IconButton onClick={toggleView("grid")}>
                                    <Apps
                                        color={
                                            view === "grid"
                                                ? "primary"
                                                : "inherit"
                                        }
                                        fontSize="small"
                                    />
                                </IconButton>
                                <IconButton onClick={toggleView("list")}>
                                    <ViewList
                                        color={
                                            view === "list"
                                                ? "primary"
                                                : "inherit"
                                        }
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Box>

                            {!!isTablet && (
                                <Sidenav
                                    handle={
                                        <IconButton>
                                            <FilterList fontSize="small" />
                                        </IconButton>
                                    }
                                >
                                    <ProductFilterCard
                                        setLocation={setLocation}
                                    />
                                </Sidenav>
                            )}
                        </FlexBox>
                    </FlexBox>
                </Card>

                <Grid container spacing={3}>
                    <Grid
                        item
                        lg={3}
                        sm={0}
                        sx={{
                            height: "fit-content",
                            display: {
                                md: "flex",
                                xs: "none",
                            },
                            flex: 1,
                        }}
                    >
                        <ProductFilterCard setLocation={setLocation} />
                    </Grid>

                    <Grid item md={9} xs={12} sx={{ flex: 3 }}>
                        <ProductCardListContainer
                            productsLength={products.results}
                            handlePaginationChange={handlePaginationChange}
                            pageInterval={PAGE_INTERVAL}
                            pagination={pagination}
                        >
                            {view === "grid" ? (
                                <ProductCard2List
                                    products={products.products}
                                    loading={loadingProducts}
                                />
                            ) : (
                                <ProductCard9List
                                    products={products.products}
                                    loading={loadingProducts}
                                />
                            )}
                        </ProductCardListContainer>
                    </Grid>
                </Grid>
            </Box>
        </NavbarLayout>
    );
};

const sortOptions = [
    {
        label: "Nombre (asc)",
        value: "asc_name",
    },
    {
        label: "Nombre (desc)",
        value: "desc_name",
    },
    {
        label: "Precio (asc)",
        value: "asc_price",
    },
    {
        label: "Precio (desc)",
        value: "desc_price",
    },
];
export { ProductList };
