import { ExchangeRates } from "api/resources/exchangeRate";
import { Context } from "contexts/ExchangeRates/ExchangeRateContext";
import { formatDistanceToNowStrict } from "date-fns";
import { ExchangeRate } from "interfaces/context/ExchangeRate";
import { useContext } from "react";
import { v4 } from "uuid";
import { useLoader } from "./useLoader";
import { useLocalStorage } from "./useLocalStorage";
import { useToast } from "./useToast";

interface UpdateDate {
    date: Date;
}
export const useExchangeRates = () => {
    const {
        state: { exchange_rate, id },
        setRate,
    } = useContext(Context);
    const { notify } = useToast();
    const { loadingGlobal, loadedGlobal } = useLoader();
    const [uuidInLocal, setUUIDInLocal] = useLocalStorage("uuid", null);

    const needsUpdateRate = () => {
        const localUpdatedDate = localStorage.getItem("updated-date");
        if (localUpdatedDate) {
            const { date }: UpdateDate = JSON.parse(localUpdatedDate);
            const updatedAt = formatDistanceToNowStrict(new Date(date), {
                unit: "hour",
            });
            const needsUpdate = parseInt(updatedAt.split(" ")[0]) >= 1;
            return needsUpdate;
        } else {
            const updatedDate = { date: new Date() };
            localStorage.setItem("updated-date", JSON.stringify(updatedDate));
        }
    };

    const getLocalRate = async () => {
        setRate({ exchange_rate: 1, id: 1 });
        setLocalRate({ exchange_rate: 1, id: 1 });
        const updatedDate = { date: new Date() };
        localStorage.setItem("updated-date", JSON.stringify(updatedDate));
        /*const localExchangeRate = localStorage.getItem("exchange-rate");
        if (localExchangeRate) {
            const localRate: ExchangeRate = JSON.parse(localExchangeRate);
            if (needsUpdateRate()) {
                const updatedRate = await getRate(localRate.id);
                setRate({ exchange_rate: updatedRate, id: localRate.id });
            } else {
                setRate(localRate);
            }
        } else {
            setRate({ exchange_rate: 1, id: 1 });
            setLocalRate({ exchange_rate: 1, id: 1 });
        }*/
    };

    const getRate = async (id: number) => {
        let uuid: string;
        if (!!!uuidInLocal || uuidInLocal === "null") {
            uuid = v4();
            setUUIDInLocal(uuid);
        } else {
            uuid = uuidInLocal;
        }
        const { data } = await ExchangeRates.select(uuid, id + 1);
        if (data) {
            const updatedDate = { date: new Date() };
            localStorage.setItem("updated-date", JSON.stringify(updatedDate));
            return data.dollars_rate;
        } else {
            notify({
                message: "Ocurrió un error seleccionando la moneda",
                type: "error",
            });

            return 1;
        }
    };

    const setLocalRate = (newRate: ExchangeRate) => {
        const strRate = JSON.stringify(newRate);
        localStorage.setItem("exchange-rate", strRate);
    };

    const selectRate = async (id: number) => {
        loadingGlobal();
        const exchange_rate = await getRate(id);
        if (exchange_rate) {
            setRate({ exchange_rate, id });
            setLocalRate({ exchange_rate, id });
        }
        loadedGlobal();
    };

    return { exchange_rate, id, selectRate, getLocalRate };
};
