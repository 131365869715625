import { axiosMethod, URL } from "./utils";

export const getAll = ({ resource, params, url }) => {
    if (url) {
        return axiosMethod({
            method: "get",
            url,
        });
    } else {
        if (params) {
            let queryParams = params ? "?" : undefined;
            let queryParamsFormatted;
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    queryParams += `${key}=${params[key]}&`;
                }
            });
            queryParamsFormatted = queryParams.slice(0, -1);
            return axiosMethod({
                method: "get",
                url: `${URL}/${resource}/${queryParamsFormatted}`,
            });
        }
        // console.log("url ", `${URL}/${resource}/`)
        return axiosMethod({
            method: "get",
            url: `${URL}/${resource}/`,
        });
    }
};

export const get = (resource, id) => {
    return axiosMethod({
        method: "get",
        url: `${URL}/${resource}/${id}/`,
    });
};

export const create = (resource, data) => {
    return axiosMethod({
        method: "post",
        url: `${URL}/${resource}/`,
        data,
    });
};

export const remove = (resource) => {
    return axiosMethod({
        method: "delete",
        url: `${URL}/${resource}`,
    });
};

export const edit = (resource, data) => {
    return axiosMethod({
        method: "put",
        url: `${URL}/${resource}`,
        data,
    });
};
