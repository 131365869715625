/* eslint-disable react-hooks/exhaustive-deps */
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "containers/FlexBox";
import { Context as CartContext } from "contexts/Cart/CartContext";
import { useContext, useState } from "react";
import Card1 from "../Card";
// import AddressList from "./AddressList";
import { Span } from "containers/Typography";
import { useCart } from "hooks/useCart";
import {
    isIOS,
    navigateToUrl,
    openInNewTab,
    whatsappMessage,
    whatsappURI,
} from "utils/utils";
import { ClientForm } from "./Forms/ClientForm";
import { useLoader } from "hooks/useLoader";
import { useNavigate } from "react-router-dom";
import { useExchangeRates } from "hooks/useExchangeRates";
import { exchangeRatesList } from "data/ExchangeRates";

const paymentMethods = [
    {
        value: "pago_movil",
        label: "Pago móvil",
    },
    {
        value: "zelle",
        label: "Zelle",
    },
    {
        value: "efectivo",
        label: "Efectivo",
    },
    {
        value: "otro",
        label: "Otro",
    },
];

export const addressInitialValues = {
    state: "",
    city: "",
    municipality: "",
    parish: "",
    urbanization: "",
    zip: "",
    references: "",
};

// const deliveryInitialValues = {
//     date: format(new Date(), "yyyy-MM-dd"),
//     time: format(new Date(), "HH:mm"),
// };

const CheckoutForm = () => {
    const navigate = useNavigate();
    const { processWhatsapp } = useCart();
    const { loadedGlobal, loadingGlobal } = useLoader();
    // const [address, setAddress] = useState(undefined);
    // const [delivery, setDelivery] = useState(deliveryInitialValues);
    const [payment, setPayment] = useState(paymentMethods[0].value);
    // const [activeAddressKey, setActiveAddressKey] = useState(undefined);

    const [comments, setComments] = useState("");
    const {
        state: { products, total, subtotal },
    } = useContext(CartContext);
    const { exchange_rate, id: exchangeId } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];

    const paymentMethodHandler = (e) => {
        const {
            target: { value },
        } = e;
        setPayment(value);
    };
    const handleComment = (e) => {
        const {
            target: { value },
        } = e;
        setComments(value);
    };
    // const handleDate = (e) => {
    //     setDelivery({ ...delivery, date: e.target.value });
    // };

    // const handleTime = (e) => {
    //     // console.log("time", e.target.value);
    //     setDelivery({ ...delivery, time: e.target.value });
    // };

    // const handleAddressSet = (values) => {
    //     setAddress(values);
    // };

    const checkout = async (clientValues) => {
        loadingGlobal();
        // const dateFormated = delivery.date.split("-");
        // const timeFormated = delivery.time.split(":");
        // const delivery_time = formatISO(
        //     new Date(...dateFormated, ...timeFormated, 0)
        // );
        const {
            guest_email,
            name,
            id_value,
            id_type,
            phone_code,
            phone_number,
        } = clientValues;

        const checkoutInfo = {
            guest_email,
            // delivery_time,
            client: {
                name,
                ci: parseInt(id_value),
                phone: `+58 ${phone_code} ${phone_number}`,
                address: null
                    // `${address?.state}${address?.city}${address?.municipality}${address?.parish}${address?.urbanization}${address?.zip}${address?.references}`
                    //     .length > 1
                    //     ? `${address?.state} ${address?.city} ${address?.municipality} ${address?.parish} ${address?.urbanization} ${address?.zip} ${address?.references}`
                    //     : null,
            },
            selected_currency: exchangeId,
            rate: exchange_rate,
        };

        const dataToWhatsapp = await processWhatsapp(checkoutInfo);
        if (!!dataToWhatsapp) {
            const { phone, orderNumber } = dataToWhatsapp;

            const whatsapMessage = whatsappMessage({
                name,
                id: `${id_type}-${id_value}`,
                email: guest_email,
                phone: `+58 ${phone_code} ${phone_number}`,
                // state: address?.state,
                // city: address?.city,
                // municipality: address?.municipality,
                // parish: address?.parish,
                // urbanization: address?.urbanization,
                // zip: address?.zip,
                // references: address?.references,
                // delivery,
                products,
                total,
                subtotal,
                comments,
                payment_method: paymentMethods.filter(
                    (method) => method.value === payment
                )[0].label,
                orderNumber,
                exchange_rate,
                symbol,
            });

            const textFormatted = encodeURIComponent(whatsapMessage);
            if (isIOS()) {
                navigateToUrl(whatsappURI({ phone, text: textFormatted }));
            } else {
                openInNewTab(whatsappURI({ phone, text: textFormatted }));
            }
            navigate("/");
        }
        loadedGlobal();
    };

    return (
        <>
            <Card1
                sx={{
                    mb: "1.5rem",
                }}
            >
                <FlexBox alignItems="center" mb={3.5}>
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            height: 32,
                            width: 32,
                            color: "primary.text",
                            mr: "0.875rem",
                        }}
                    >
                        1
                    </Avatar>
                    <Typography fontSize="20px">Detalle del cliente</Typography>
                </FlexBox>

                <ClientForm checkout={checkout} />
            </Card1>

            {/* <Card1
                sx={{
                    mb: "1.5rem",
                }}
            >
                <AddressList
                    handleAddressSet={handleAddressSet}
                    activeKey={activeAddressKey}
                    setActiveKey={setActiveAddressKey}
                />
                 <Grid container spacing={3} mt={1}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            type="date"
                            label="Fecha deseable de entrega"
                            fullWidth
                            value={delivery.date}
                            onChange={handleDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            type="time"
                            label="Hora deseable de entrega"
                            fullWidth
                            value={delivery.time}
                            onChange={handleTime}
                        />
                    </Grid>
                </Grid> 
            </Card1> */}
            <Card1
                sx={{
                    mb: "1.5rem",
                }}
            >
                <FlexBox alignItems="center" mb={3.5}>
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            height: 32,
                            width: 32,
                            color: "primary.text",
                            mr: "0.875rem",
                        }}
                    >
                        2
                    </Avatar>
                    <Typography fontSize="20px">Método de pago</Typography>
                </FlexBox>

                <Box
                    mb={1}
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        gap: 10,
                    }}
                >
                    <FormControl
                        sx={{
                            width: "100%",
                        }}
                    >
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                marginBottom: 1,
                            }}
                            value={payment.method}
                            onChange={paymentMethodHandler}
                        >
                            {paymentMethods.map(({ value, label }) => (
                                <FormControlLabel
                                    value={value}
                                    control={<Radio />}
                                    label={label}
                                    key={value}
                                    labelPlacement="top"
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Card1>
            <Card1
                sx={{
                    mb: "1.5rem",
                }}
            >
                <FlexBox alignItems="center" mb={3.5}>
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            height: 32,
                            width: 32,
                            color: "primary.text",
                            mr: "0.875rem",
                        }}
                    >
                        3
                    </Avatar>
                    <Typography fontSize="20px">Concreta tu orden</Typography>
                </FlexBox>
                <Box
                    mb={1}
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FlexBox alignItems="center" mb={2}>
                        <Span fontWeight="600" mr={1.25}>
                            Comentarios adicionales
                        </Span>
                    </FlexBox>

                    <TextField
                        variant="outlined"
                        rows={6}
                        fullWidth
                        multiline
                        sx={{
                            mb: "1rem",
                        }}
                        onChange={handleComment}
                        value={comments}
                    />

                    <Divider
                        sx={{
                            mb: "1rem",
                        }}
                    />
                    <Grid container>
                        <Button
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            form="client-form"
                            startIcon={<WhatsAppIcon />}
                            sx={{
                                color: "white",
                                textTransform: "none",
                            }}
                        >
                            Enviar mi pedido por Whatsapp
                        </Button>
                    </Grid>
                </Box>
            </Card1>
        </>
    );
};

export default CheckoutForm;
