import React from "react";
import { eCommerceTheme } from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

const MuiTheme = ({ children }) => {
  return (
    <ThemeProvider theme={eCommerceTheme()} >
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiTheme;
