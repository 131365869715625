import { SELECTED_ENVIRONTMENT } from "api/config";
import axios from "axios";
import https from "https"

const TIMEOUT_INTERVAL = 50000;

const handleResponse = (response) => {
    // console.log("response", response)
    if (response.results) {
        return response.results;
    }

    if (response.data) {
        return response.data;
    }

    return response;
};

const handleError = (error) => {
    if (error.message.includes("timeout")) {
        return error.message;
    }
    if (error.data) {
        return error.data;
    }
    return error;
};

export const { url: URL, domain: DOMAIN } = SELECTED_ENVIRONTMENT;

export const axiosMethod = async (request) => {
    // console.log("req", {
    //     ...request,
    //     timeout: TIMEOUT_INTERVAL,
    //     withCredentials: true,
    //     xsrfCookieName: "csrftoken",
    //     xsrfHeaderName: "X-CSRFTOKEN",
    //     httpAgent: https.Agent({  
    //         rejectUnauthorized: false
    //       })
    // })
    try {
        const response = await axios({
            ...request,
            timeout: TIMEOUT_INTERVAL,
            withCredentials: true,
            xsrfCookieName: "csrftoken",
            xsrfHeaderName: "X-CSRFTOKEN",
            httpAgent: https.Agent({  
                rejectUnauthorized: false
              })
        });
        return { data: handleResponse(response), error: null };
    } catch (error) {
        return { data: null, error: handleError(error) };
    }
};
