import { Box, styled } from '@mui/material'
import React from 'react'
const Wrapper = styled(Box)({
  backgroundColor: "black",
  position: "absolute",
  top: 0,
  right: 20,
  zIndex: 15,
  color: "white",
  height: "45px",
  width: "55px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
})
export const NewBadge = () => {
  return (
    <Wrapper>Nuevo</Wrapper>
  )
}
