/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CategorySectionCreator from "containers/CategorySectionCreator";
import useWindowSize from "hooks/useWindowSize";
import Carousel from "components/Carousel/Carousel";
import { useToast } from "hooks/useToast";
import { BestSellingProductsCarousel } from "api/resources/carousels";
import ProductCard2 from "components/ProductsCards/ProductCard2";
import { ProductCard2Skeleton } from "components/ProductsCards/Skeletons/ProductCard2Skeleton";

export const TopRated = () => {
    const [visibleSlides, setVisibleSlides] = useState(4);
    const width = useWindowSize();
    const [carouselData, setCarouselData] = useState([]);
    const { notify } = useToast();
    const [loading, setLoading] = useState(true);
    const setData = useCallback(async () => {
        setLoading(true);
        const carousel = await BestSellingProductsCarousel.getAll();
        if (carousel.data) {
            setCarouselData(carousel.data);
        } else if (carousel.error) {
            notify({
                message:
                    "Ocurrio un error obteniendo la data de nuestros productos mejor vendidos",
                type: "error",
            });
        }
        setLoading(false);
    },[]);
    useEffect(() => setData(), [setData]);
    useEffect(() => {
        if (width < 425) setVisibleSlides(1);
        else if (width < 768) setVisibleSlides(2);
        else setVisibleSlides(3);
    }, [width]);
    return (
        <CategorySectionCreator
            title={
                loading || carouselData.length > 2 ? "Top más vendidos" : null
            }
            seeMoreLink="/products?best_selling=True"
            subtitle={
                loading || carouselData.length > 2
                    ? "Los favoritos de nuestros clientes y proveedores"
                    : null
            }
        >
            {loading || carouselData.length > 2 ? (
                <Box mt={-0.5} mb={-0.5}>
                    {!loading ? (
                        <Carousel
                            totalSlides={carouselData.length}
                            visibleSlides={
                                carouselData.length > visibleSlides
                                    ? visibleSlides
                                    : carouselData.length
                            }
                            showArrow={carouselData.length > visibleSlides}
                        >
                            {carouselData.length > 2 &&
                                carouselData.map((item, ind) => (
                                    <Box py={0.5} key={ind}>
                                        <ProductCard2 product={item} />
                                    </Box>
                                ))}
                        </Carousel>
                    ) : (
                        <Carousel
                            totalSlides={visibleSlides}
                            visibleSlides={visibleSlides}
                            showArrow={false}
                        >
                            {Array.apply(null, Array(visibleSlides))
                                .map(function () {return null})
                                .map((item, ind) => (
                                    <Box py={0.5} key={ind}>
                                        <ProductCard2Skeleton />
                                    </Box>
                                ))}
                        </Carousel>
                    )}
                </Box>
            ) : null}
        </CategorySectionCreator>
    );
};
