import bsIcon from "assets/icons/exchanges/bs.png";
import euroIcon from "assets/icons/exchanges/euro.png";
import dollarIcon from "assets/icons/exchanges/dollar.png";

export const exchangeRatesList = [
    {
        label: "Bolívares",
        icon: bsIcon,
        symbol: "Bs",
    },
    {
        label: "Dólares",
        icon: dollarIcon,
        symbol: "$",
    },
    {
        label: "Euros",
        icon: euroIcon,
        symbol: "€",
    },
];
