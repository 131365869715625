import FlexBox from "containers/FlexBox";
import { H5, Small, Span } from "containers/Typography";
import Add from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment } from "react";
import ProductCard9Style from "./ProductCard9Style";
import defaultProduct from "assets/images/productoDefault.png";
import { Link } from "react-router-dom";
// import { SELECTED_ENVIRONTMENT } from "api/config";
import { useCart } from "hooks/useCart";
import { NewBadge } from "./NewBadge";
import { SELECTED_ENVIRONTMENT } from "api/config";
import { OfferBadge } from "./OfferBadge";
import { reduceTitle } from "utils/utils";
import { useExchangeRates } from "hooks/useExchangeRates";
import { exchangeRatesList } from "data/ExchangeRates";
import { Size } from "interfaces/image";
import { filterImagesBySize } from "utils/Images";
import Remove from "@mui/icons-material/Remove";
import HoverBox from "components/HoverBox";

// const { resources } = SELECTED_ENVIRONTMENT

const ProductCard9 = ({ product }) => {
    const { addToCart, removeInCart, subInCart, getCountOnCart } = useCart();
    const {
        title,
        stockrecords,
        discount_percentage,
        id,
        images,
        is_new,
        description,
    } = product;
    const image = filterImagesBySize(images, Size.Small)[0].original;
    const imgUrl = SELECTED_ENVIRONTMENT.resources + image;
    const price = stockrecords[0].price;
    const discount = !!parseFloat(discount_percentage)
        ? parseFloat(discount_percentage)
        : 0;
    let quantity = getCountOnCart(id);
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];
    return (
        // overflow="hidden" width="100%" {...props}
        <Fragment>
            <ProductCard9Style>
                <Grid container spacing={1}>
                    <Grid item sm={3} xs={12}>
                        <Box position="relative">
                            {!!is_new && <NewBadge />}
                            {!!discount && <OfferBadge discount={discount} />}
                            <HoverBox
                                borderRadius="8px"
                                mb={0.25}
                                sx={{
                                    width: "100%",
                                    paddingTop: "100%",
                                    height: 0,
                                }}
                            >
                                {images.length > 0 ? (
                                    <img
                                        src={imgUrl}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt={title}
                                    />
                                ) : (
                                    <img
                                        src={defaultProduct}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            width: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt={title}
                                    />
                                )}
                            </HoverBox>
                        </Box>
                    </Grid>

                    <Grid item sm={7} xs={12}>
                        <FlexBox
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                            p={2}
                        >
                            <Link to={`/products/${id}`}>
                                <H5 fontWeight="600" my="0.5rem">
                                    {title}
                                </H5>
                            </Link>
                            {description && (
                                <Small>{reduceTitle(description, 200)}</Small>
                            )}

                            <FlexBox mt={1} mb={2} alignItems="center">
                                {!!discount && (
                                    <Span
                                        mr={1}
                                        fontWeight="600"
                                        color="grey.600"
                                    >
                                        <del>
                                            {`${symbol} `}
                                            {(
                                                (price -
                                                    (price * discount) / 100) *
                                                exchange_rate
                                            ).toFixed(2)}
                                        </del>
                                    </Span>
                                )}
                                <H5
                                    fontWeight={600}
                                    color="primary.main"
                                    mr={1}
                                >
                                    {`${symbol} `}
                                    {(price * exchange_rate)?.toFixed(2)}
                                </H5>
                            </FlexBox>
                        </FlexBox>
                    </Grid>

                    <Grid item sm={2} xs={12}>
                        <FlexBox
                            className="add-cart"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                            margin="auto"
                            padding="1rem 0"
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={quantity === 0}
                                sx={{
                                    p: "5px",
                                }}
                                onClick={() => {
                                    if (quantity === 1) {
                                        removeInCart(product);
                                    } else {
                                        subInCart(product);
                                    }
                                }}
                            >
                                <Remove fontSize="small" />
                            </Button>
                            <Span mx={1} fontWeight="600" fontSize="15px">
                                {quantity}
                            </Span>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    p: "5px",
                                }}
                                onClick={() => addToCart(product)}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </FlexBox>
                    </Grid>
                </Grid>
            </ProductCard9Style>
        </Fragment>
    );
};

export default ProductCard9;
