import { Grid } from "@mui/material";
import React from "react";
import ProductCard2 from "components/ProductsCards/ProductCard2";
import { ProductCard2Skeleton } from "components/ProductsCards/Skeletons/ProductCard2Skeleton";

const ProductCard2List = ({ products, loading }) => {
    return (
        <div>
            <Grid container spacing={3}>
                {!loading
                    ? products.slice(0, products.length).map((item, ind) => (
                          <Grid item lg={4} sm={6} xs={12} key={ind}>
                              <ProductCard2
                                  product={item}
                              />
                          </Grid>
                      ))
                    : Array.apply(null, Array(4))
                          .map(function () {
                              return null;
                          })
                          .map((item, ind) => (
                              <Grid item lg={4} sm={6} xs={12} key={ind}>
                                  <ProductCard2Skeleton />
                              </Grid>
                          ))}
            </Grid>
        </div>
    );
};

export default ProductCard2List;
