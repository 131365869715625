import { Box, Container } from "@mui/material";
import React from "react";
import SectionMiddleHeader from "./SectionMiddleHeader";

const SectionMiddleCreator = ({ title, subtitle, children }) => {
    return (
        <>
            {children ? (
                <Box mb={5}>
                    <Container
                        sx={{
                            pb: "1rem",
                        }}
                    >
                        <SectionMiddleHeader
                            title={title}
                            subtitle={subtitle}
                        />

                        {children}
                    </Container>
                </Box>
            ) : null}
        </>
    );
};

export default SectionMiddleCreator;
