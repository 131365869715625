/* eslint-disable react-hooks/exhaustive-deps */

import { Paragraph } from "containers/Typography";
import { Box, MenuItem } from "@mui/material";

import React from "react";
import { strFirstLetterUpperCase, updateQuery } from "utils/utils";
import { useLocation } from "react-router-dom";

const FilterMenuItem = ({ name, slug, subcategories, level, afterClick }) => {
    const { pathname, search } = useLocation();

    const handleCategoryClick = (slug, label) => {
        const categoryChange = updateQuery(pathname, search, "category", slug);
        const [newPathname, newSearch] = categoryChange.split("?");
        const newSearchFormatted = "?" + newSearch;
        afterClick(
            updateQuery(newPathname, newSearchFormatted, "categoryLabel", label)
        );
    };
    const fontSize = 14 - level * 1;

    return (
        <>
            {level <= 3 ? (
                <>
                    <MenuItem onClick={() => handleCategoryClick(slug, name)}>
                        <Paragraph
                            className="cursor-pointer"
                            fontSize={`${fontSize}px`}
                            color="grey.600"
                        >
                            {strFirstLetterUpperCase(name)}
                        </Paragraph>
                    </MenuItem>
                    {!!subcategories
                        ? subcategories.map(({ name, slug, subcategories }) => (
                              <Box key={slug} ml={1}>
                                  <FilterMenuItem
                                      name={name}
                                      slug={slug}
                                      subcategories={subcategories}
                                      level={level + 1}
                                      afterClick={afterClick}
                                  />
                              </Box>
                          ))
                        : null}
                </>
            ) : null}
        </>
    );
};

export default FilterMenuItem;
