import HoverBox from "../HoverBox";
import { H4, Span } from "containers/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { styled, Box, Button } from "@mui/material";
// import { SELECTED_ENVIRONTMENT } from "api/config";
import defaultProduct from "assets/images/productoDefault.png";
import BazarCard from "components/BazarCard";
import { useCart } from "hooks/useCart";
import FlexBox from "containers/FlexBox";
import Add from "@mui/icons-material/Add";
import { NewBadge } from "./NewBadge";
import { OfferBadge } from "./OfferBadge";
import { SELECTED_ENVIRONTMENT } from "api/config";
import { useExchangeRates } from "hooks/useExchangeRates";
import { exchangeRatesList } from "data/ExchangeRates";
import { filterImagesBySize } from "utils/Images";
import { Size } from "interfaces/image";
import Remove from "@mui/icons-material/Remove";

// const { resources } = SELECTED_ENVIRONTMENT;

const StyledBazarCard = styled(BazarCard)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",
    "&:hover": {
        "& .css-1i2n18j": {
            display: "flex",
        },
    },
}));
const ContentWrapper = styled(Box)(() => ({
    padding: "0.5rem",
    "& .title, & .categories": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));
const ProductCard2 = ({ product, size = Size.Medium }) => {
    const { addToCart, subInCart, getCountOnCart, removeInCart } = useCart();
    const { title, stockrecords, id, images, discount_percentage, is_new } =
        product;
    let imgUrl;
    if (images.length > 0) {
        const image = filterImagesBySize(images, size)[0].original;
        imgUrl = SELECTED_ENVIRONTMENT.resources + image;
    }
    let quantity = getCountOnCart(id);
    const price = stockrecords[0].price;
    const discount = !!parseFloat(discount_percentage)
        ? parseFloat(discount_percentage)
        : 0;
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];

    return (
        <StyledBazarCard>
            {is_new ? <NewBadge /> : null}
            {discount ? <OfferBadge discount={discount} /> : null}
            <Link to={`/products/${id}`}>
                <HoverBox
                    borderRadius="8px"
                    mb={0.25}
                    sx={{ width: "100%", paddingTop: "100%", height: 0 }}
                >
                    {images.length > 0 ? (
                        <img
                            src={imgUrl}
                            style={{
                                position: "absolute",
                                top: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            alt={title}
                        />
                    ) : (
                        <img
                            src={defaultProduct}
                            style={{
                                position: "absolute",
                                top: 0,
                                width: "100%",
                                objectFit: "cover",
                            }}
                            alt={title}
                        />
                    )}
                </HoverBox>
            </Link>
            <ContentWrapper>
                <FlexBox>
                    <Box flex="1 1 0" minWidth="0px" mr={1}>
                        <Link to={`/products/${id}`}>
                            <H4
                                className="title"
                                fontSize="14px"
                                textAlign="left"
                                fontWeight="600"
                                color="text.secondary"
                                mb={1}
                                title={title}
                            >
                                {title}
                            </H4>
                        </Link>

                        <FlexBox
                            alignItems="center"
                            justifyContent="space-between"
                            mt={0.5}
                        >
                            <Box>
                                <Box
                                    pr={1}
                                    fontWeight="600"
                                    color="secondary.main"
                                    display="flex"
                                >
                                    {!!discount && (
                                        <Box
                                            color="grey.600"
                                            fontWeight="600"
                                            mr={1}
                                        >
                                            <del>
                                                $
                                                {(
                                                    price * exchange_rate
                                                )?.toFixed(2)}
                                            </del>
                                        </Box>
                                    )}
                                    {`${symbol} `}
                                    {discount
                                        ? (
                                              (price -
                                                  (price * discount) / 100) *
                                              exchange_rate
                                          ).toFixed(2)
                                        : (price * exchange_rate)?.toFixed(2)}
                                </Box>
                            </Box>
                            <FlexBox
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={quantity === 0}
                                    sx={{
                                        p: "5px",
                                    }}
                                    onClick={() => {
                                        if (quantity === 1) {
                                            removeInCart(product);
                                        } else {
                                            subInCart(product);
                                        }
                                    }}
                                >
                                    <Remove fontSize="small" />
                                </Button>
                                <Span mx={1} fontWeight="600" fontSize="15px">
                                    {quantity}
                                </Span>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        p: "5px",
                                    }}
                                    onClick={() => addToCart(product)}
                                >
                                    <Add fontSize="small" />
                                </Button>
                            </FlexBox>
                        </FlexBox>
                    </Box>
                </FlexBox>
            </ContentWrapper>
        </StyledBazarCard>
    );
};

export default ProductCard2;
