import React from "react";
import MainCarousel from "containers/HomeSections/MainCarousel";
import FlashDeals from "containers/HomeSections/FlashDeals";
import TopCategories from "containers/HomeSections/TopCategories";
import AppLayout from "containers/Layout/AppLayout";
import NewArrivals from "containers/HomeSections/NewArrivals";
import { CategoriesResume } from "containers/HomeSections/CategoriesResume";
import { Partners } from "containers/HomeSections/Partners";
import { TopRated } from "containers/HomeSections/TopRated";
import Imagine from "containers/HomeSections/Imagine";
import Silk from "containers/HomeSections/Silk";
import Offers from "containers/HomeSections/Offers";
import { Banner } from "containers/HomeSections/Banner";

const Home = () => {

    return (
        <AppLayout>
            <MainCarousel/>
            <CategoriesResume />
            <TopCategories />
            <Partners />
            <FlashDeals />
            <NewArrivals />
            <TopRated />
            <Offers />
            <Banner />
            <Imagine />
            <Silk />
        </AppLayout>
    );
};

export { Home };

