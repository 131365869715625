import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
const Card1 = styled(Card)(({theme})=>({
    position: "relative",
    padding: "1.5rem 1.75rem",
    [theme.breakpoints.down("md")]: {
        padding: "1rem",
    },
}));
export default Card1;
