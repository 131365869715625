import { Card } from "@mui/material";
import React from "react";
import StyledMegaMenu from "./StyledMegaMenu"; // component props with nested interface
import CategoryMenuItem from "../CategoryMenuItem";
import { strFirstLetterUpperCase } from "utils/utils";

const MegaMenu1 = ({ categories }) => {
    return categories?.length > 0 ? (
        <StyledMegaMenu>
            <Card
                elevation={2}
                sx={{
                    ml: "1rem",
                }}
            >
                {categories?.map(({ name, slug }, ind) => (
                    <CategoryMenuItem
                        title={strFirstLetterUpperCase(name)}
                        href={`/products?category=${slug}&categoryLabel=${name}`}
                        key={ind}
                        absolute={true}
                    />
                        
                ))}
            </Card>
        </StyledMegaMenu>
    ) : null;
};

MegaMenu1.defaultProps = {
    minWidth: "760px",
};
export default MegaMenu1;
