import BazarImage from "../BazarImage";
import { Box, Skeleton, styled } from "@mui/material";
import React from "react";
import useWindowSize from "hooks/useWindowSize"; // component props interface
import { handlePath } from "utils/utils";
import { Link } from "react-router-dom";

// styled component
const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    position: "relative",
    ".card-info": {
        position: "absolute",
        zIndex: 3,
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
    },
    ".title": {
        fontSize: 40,
        marginTop: 0,
        marginBottom: "1.35rem",
        lineHeight: 1.2,
        color: "white",
        whiteSpace: "normal",
        marginLeft: 20,
    },
    [theme.breakpoints.up("sm")]: {
        ".grid-item": {
            minHeight: 424,
            display: "flex",
            alignItems: "baseline",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        paddingLeft: 0,
        ".title": {
            fontSize: 18,
        },
    },
    [theme.breakpoints.down("xs")]: {
        ".title": {
            fontSize: 16,
        },
        ".title + *": {
            fontSize: 13,
        },
        ".button-link": {
            height: 36,
            padding: "0 1.5rem",
            fontSize: 13,
        },
    },
}));

const CarouselCard = ({ carousel }) => {
    const width = useWindowSize();
    const { anchor, path } = handlePath(carousel.redirect_url);
    const CardImage = (
        <BazarImage
            src={carousel.image}
            alt="apple-watch-1"
            sx={{
                display: "block",
                mx: "auto",
                height: "100%",
                objectFit: "cover",
                width: "100%",
                zIndex: 2,
            }}
        />
    );
    return (
        <StyledBox>
            {carousel.title  ? (
                <Box className="card-info">
                    <h1 className="title">{carousel.title}</h1>
                </Box>
            ) : null}
            {anchor ? (
                <a
                    href={`${path}`}
                    style={{
                        width: "100%",
                        height: `${(width * 3) / 7}px`,
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    {CardImage}
                </a>
            ) : (
                <Link
                    to={path}
                    style={{
                        width: "100%",
                        height: `${(width * 3) / 7}px`,
                    }}
                >
                    {CardImage}
                </Link>
            )}
        </StyledBox>
    );
};

export const CarouselCardSkeleton = () => {
    return (
        <StyledBox>
            <Skeleton variant="rectangular" width={"100%"} height={"50vh"} />
        </StyledBox>
    );
};

export default CarouselCard;
