import FlexBox from "containers/FlexBox";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { buttons } from "data/FieldsContent/404Fields";
import { H2 } from "containers/Typography";

const RequiredCheckout = () => {
    return (
        <FlexBox
            flexDirection="column"
            minHeight="100vh"
            justifyContent="center"
            alignItems="center"
            px={2}
        >
            <H2>Necesita realizar su confirmación de compra correctamente para acceder a esta vista</H2>
            <FlexBox flexWrap="wrap">
                        <Link to="/">
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    m: "0.5rem",
                                }}
                            >
                                {buttons.goHome}
                            </Button>
                        </Link>
                <Link to="/cart">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            m: "0.5rem",
                        }}
                    >
                        Ir al carrito
                    </Button>
                </Link>
            </FlexBox>
        </FlexBox>
    );
};

export { RequiredCheckout };
