import { ExchangeRate } from "interfaces/context/ExchangeRate";
import { Action } from "interfaces/context/ExchangeRate";

const initialState: ExchangeRate = {
    exchange_rate: 1,
    id: 0,
};

const exchangeRateReducer = (
    state: ExchangeRate,
    { type, payload: { exchange_rate, id } }: Action
) => {
    switch (type) {
        case "set_rate":
            return {
                exchange_rate,
                id,
            };
        default:
            return state;
    }
};

export { exchangeRateReducer, initialState };
