/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from "react";
import NavbarLayout from "containers/Layout/NavbarLayout";
import ProductIntro from "components/Products/ProductIntro";
import { useParams } from "react-router-dom";
import { Products } from "api/resources/products";
import ProductIntroSkeleton from "components/Products/ProductIntroSkeleton";
import { useToast } from "hooks/useToast";

const ProductDetails = () => {
    const [product, setProduct] = useState(null);
    console.log("🚀 ~ file: ProductDetails.jsx ~ line 13 ~ ProductDetails ~ product", product)
    const [loading, setLoading] = useState(true);
    let { id } = useParams();
    const  { notify } = useToast()

    const getProduct = useCallback(async (id) => {
        setLoading(true);
        await Products.get(id).then((res) => {
            if (res.error) {
                notify({message:"Ocurrió un error obteniendo la data del producto", type: "error"})
            } else {
                setProduct(res.data);
            }
        });
        setLoading(false);
    },[]);
    useEffect(() => {
        getProduct(id);
    }, [id, getProduct]);
    return (
        <NavbarLayout>
            {!loading && product ? (
                <ProductIntro product={product} />
            ) : (
                <ProductIntroSkeleton />
            )}
        </NavbarLayout>
    );
};

export const getStaticPaths = async () => {
    return {
        paths: [],
        //indicates that no page needs be created at build time
        fallback: "blocking", //indicates the type of fallback
    };
};

export { ProductDetails };
