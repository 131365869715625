import Home from "icons/Home";
import ShoppingBagOutlined from "icons/ShoppingBagOutlined";

import useWindowSize from "hooks/useWindowSize";
import { Badge, Box, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { layoutConstant } from "utils/constants";
import React, { useContext } from "react"; // styled components
import { Context as CartContext } from "contexts/Cart/CartContext";
import { Link } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
    display: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: layoutConstant.mobileNavHeight,
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 1px 4px 3px rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    "@media only screen and (max-width: 900px)": {
        display: "flex",
        width: "100vw",
    },
}));
const StyledNavLink = styled(Link)(({ theme }) => ({
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13px",
    color: theme.palette.primary.main,
}));

const MobileNavigationBar = () => {
    const width = useWindowSize();
    const {
        state: { products },
    } = useContext(CartContext);
    const { palette } = useTheme();

    const iconStyle = {
        marginBottom: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: palette.primary.main,
    };
    return width <= 900 ? (
        <Wrapper>
            {list.map((item) => (
                <StyledNavLink to={item.href} key={item.title}>
                    {item.title === "Cart" ? (
                        <Badge badgeContent={products.length} color="primary">
                            <item.icon fontSize="small" sx={iconStyle} />
                        </Badge>
                    ) : (
                        <item.icon sx={iconStyle} fontSize="small" />
                    )}

                    {item.title}
                </StyledNavLink>
            ))}
        </Wrapper>
    ) : null;
};

const list = [
    {
        title: "Home",
        icon: Home,
        href: "/",
    },
    // {
    //   title: "Category",
    //   icon: CategoryOutlined,
    //   href: "/mobile-category-nav",
    // },
    {
        title: "Cart",
        icon: ShoppingBagOutlined,
        href: "/cart",
    },
    // {
    //   title: "Account",
    //   icon: User2,
    //   href: "/profile",
    // },
];
export default MobileNavigationBar;
