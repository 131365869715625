const grey = {
    900: "#2B3445",
    // Main Text
    800: "#373F50",
    // Paragraph
    700: "#4B566B",
    600: "#7D879C",
    // Low Priority form Title/Text
    500: "#AEB4BE",
    400: "#DAE1E7",
    // Border
    300: "#E3E9EF",
    200: "#F3F5F9",
    // Line Stroke
    100: "#F7F7F7",
};
export const marron = {
    50: "#f3f5f9",
    100: "#F6F2ED",
    200: "#F8DBD1",
    300: "#EBBCB3",
    400: "#D89C98",
    main: "#BE7374",
    600: "#A3545C",
    700: "#883948",
    800: "#6E2438",
    900: "#5B162F",
};
export const paste = {
    50: "#F5F5F5",
    // 50: "#fff",
    100: "#DDFBF1",
    200: "#BDF7E8",
    300: "#97E8DA",
    400: "#76D2CA",
    main: "#4BB4B4",
    600: "#36929A",
    700: "#257181",
    800: "#175368",
    900: "#0E3D56",
    contrastText: "#FFFFFF",
};
export const palette = {
    secondary: {
        main: "#000000",
    },
    primary: {
        main: "#000000",
    },
    info: {
        main: "#377dff",
    },
    success: {
        main: "#44af69",
    },
    error: {
        main: "#c33147",
    },
    warning: {
        main: "#f0c808",
    },
    text: {
        primary: grey[900],
        secondary: grey[800],
        disabled: grey[400],
    },
    softBackground: {
        secondary: "rgba(0, 0, 0, 0.1)"
    },
    divider: grey[200],
    grey: { ...grey },
    paste: { ...paste },
    marron: { ...marron },
    background: {
        default: grey[100],
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
};
