import { addMultiple, addProduct, deleteProduct, init } from "api/services/basket";
import { get, getAll } from "api/services/crud";

const basketsResource = "baskets";
const basketResource = "basket";

export const Basket = {
    getAll: () => getAll({ resource: basketsResource }),
    get: (id) => get(basketsResource, id),
    init: (uuid) => init(basketResource, uuid),
    addProduct: (productUrl, quantity) =>
        addProduct(basketResource, productUrl, quantity),
    deleteProduct: (productId) => deleteProduct(basketResource, productId),
    addMultiple: (productList, uuid) => addMultiple(basketResource, productList, uuid)
};
