import { SvgIcon } from "@mui/material";
import React from "react";

const TruckFilled = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1302 7.5H22.4118C22.8992 7.5 23.2941 7.89504 23.2941 8.38236V10.1471H28.322C28.8735 10.1471 29.3583 10.5127 29.5098 11.043L31.0223 16.3368C31.1997 16.368 31.3647 16.4529 31.4937 16.5819L32.7416 17.8298C32.907 17.9952 33 18.2198 33 18.4538V24.2648C33 24.752 32.6049 25.1471 32.1177 25.1471H29.4707C29.4707 27.0963 27.8904 28.6764 25.9412 28.6764C23.9919 28.6764 22.4118 27.0963 22.4118 25.1471H13.5882C13.5882 27.0963 12.0081 28.6764 10.0588 28.6764C8.10959 28.6764 6.52941 27.0963 6.52941 25.1471H3.88236C3.39504 25.1471 3 24.752 3 24.2648V9.63019C3 9.39618 3.09296 9.17175 3.25844 9.00627L4.50627 7.75843C4.67175 7.59295 4.89618 7.5 5.1302 7.5ZM29.1833 16.3236L27.9227 11.9118H23.2941V15.4412C23.2941 15.9285 23.6892 16.3236 24.1764 16.3236H29.1833ZM25.9412 23.3823C26.9159 23.3823 27.7059 24.1725 27.7059 25.1471C27.7059 26.1218 26.9159 26.9118 25.9412 26.9118C24.9666 26.9118 24.1764 26.1218 24.1764 25.1471C24.1764 24.1725 24.9666 23.3823 25.9412 23.3823ZM8.29412 25.1471C8.29412 26.1218 9.08421 26.9118 10.0588 26.9118C11.0334 26.9118 11.8235 26.1218 11.8235 25.1471C11.8235 24.1725 11.0334 23.3823 10.0588 23.3823C9.08421 23.3823 8.29412 24.1725 8.29412 25.1471Z"
      />
    </SvgIcon>
  );
};

export default TruckFilled;
