import BazarCard from "../../BazarCard";
import { Skeleton, styled } from "@mui/material";
import React from "react";

const StyledBazarCard = styled(BazarCard)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "225px",
    width: "225px",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",
    "&:hover": {
        "& .css-1i2n18j": {
            display: "flex",
        },
    },
}));

export const BrandSkeleton = () => {
    return (
        <StyledBazarCard>
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                />
        </StyledBazarCard>
    );
};

