import { Box, styled } from "@mui/material";
import React from "react";
import { H2,  H6 } from "./Typography";

const Wrapper = styled(Box)({
  paddingBottom: "5px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h2, & h6": {
        textAlign: "center",
    }
})

const SectionMiddleHeader = ({ title, subtitle }) => {
  return (
    <Wrapper>
        <H2 fontWeight="bold" lineHeight="1">
          {title}
        </H2>
        <H6>{subtitle}</H6>
    </Wrapper>
  );
};

export default SectionMiddleHeader;
