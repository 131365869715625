import { getAll } from "../services/crud";

const base = "page-content/"
const mainCarouselResource = "main-carousel";
const newProductsCarouselResource = "new-products-carousel";
const bestSellingProductsCarouselResource = "products/best-selling"
const bestPricesCarouselResource = "best-prices-carousel"
const brandsCarouselResource = "brands-carousel"

export const MainCarousel = {
    getAll: () => getAll({resource: base + mainCarouselResource}),
};

export const NewProductCarousel = {
    getAll: () => getAll({resource: base + newProductsCarouselResource}),
};

export const BestSellingProductsCarousel = {
    getAll: () => getAll({resource: bestSellingProductsCarouselResource}),
};

export const BestPricesCarousel = {
    getAll: () => getAll({resource: base + bestPricesCarouselResource}),
};

export const BrandsCarousel = {
    getAll: () => getAll({resource: base + brandsCarouselResource}),
};
