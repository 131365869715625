import { Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import ProductCard9Style from "../ProductCard9Style";

const ProductCard9Skeleton = () => {
    return (
        <Fragment>
            <ProductCard9Style>
                <Skeleton variant="rectangular" width={"100%"} height={150} />
            </ProductCard9Style>
        </Fragment>
    );
};

export default ProductCard9Skeleton;
