import BazarButton from "components/BazarButton";
import Image from "components/BazarImage";
import CategoryMenu from "components/Categories/CategoryMenu";
import FlexBox from "../FlexBox";
import Category from "icons/Category";
import ShoppingBagOutlined from "icons/ShoppingBagOutlined";
import MiniCart from "components/MiniCart";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
    Badge,
    Box,
    Container,
    Drawer,
    IconButton,
    styled,
} from "@mui/material";
import { layoutConstant } from "utils/constants";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import SearchBox from "components/SearchBox"; // component props interface
// import { logo } from "data/mockData";
import BlackLogo from "assets/images/black-logo.png";
import { Link } from "react-router-dom";
import { Context as CartContext } from "contexts/Cart/CartContext";
// import config from "assets/icons/exchanges/config.png";
// import ExchangeRateMenu from "components/ExchangeRates/ExchangeRatesMenu";
// import { exchangeRatesList } from "data/ExchangeRates";
// import { useExchangeRates } from "hooks/useExchangeRates";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    zIndex: 1,
    height: layoutConstant.headerHeight,
    background: theme.palette.background.paper,
    transition: "height 250ms ease-in-out",
    [theme.breakpoints.down("sm")]: {
        height: layoutConstant.mobileHeaderHeight,
    },
}));

// const ExchangeHandler = styled(Box)(({ theme }) => ({
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//     alignItems: "center",
//     backgroundColor: theme.palette.primary.main,
//     width: "120px",
//     height: "45px",
//     padding: "10px 15px",
//     borderRadius: "20px",
//     marginLeft: "10px",
//     color: theme.palette.primary.contrastText,
//     fontWeight: "bold",
//     "&:hover": {
//         cursor: "pointer",
//     },
//     ".icon-config": {
//         height: "20px",
//         width: "20px",
//     },
//     ".selected-icon": {
//         width: "35px",
//         height: "20px",
//         filter: "grayscale(1) invert(1)",
//     },
//     [theme.breakpoints.down("md")]: {
//         width: "80px",
//         ".icon-config": {
//             display: "none",
//         },
//     },
// }));

const Header = ({ isFixed, className }) => {
    const [sidenavOpen, setSidenavOpen] = useState(false);
    // const { id: exchangeID } = useExchangeRates();

    const toggleSidenav = () => setSidenavOpen(!sidenavOpen);

    const {
        state: { products },
    } = useContext(CartContext);
    let productsQuantity = 0;
    products.forEach(({ quantity }) => (productsQuantity += quantity));

    const cartHandle = (
        <Badge
            badgeContent={productsQuantity}
            color="primary"
            sx={{
                display: {
                    xs: "none",
                    md: "flex",
                },
            }}
        >
            <Box
                component={IconButton}
                ml={2.5}
                bgcolor="softBackground.secondary"
                p={1.25}
                onClick={toggleSidenav}
            >
                <ShoppingBagOutlined />
            </Box>
        </Badge>
    );
    // const exchangeHandler = (
    //     <ExchangeHandler>
    //         <img
    //             src={config}
    //             className="icon-config"
    //             alt="exchange rate handler"
    //         />
    //         <img
    //             src={exchangeRatesList[exchangeID].icon}
    //             className="selected-icon"
    //             alt={`exchange selected: ${exchangeRatesList[0].label}`}
    //         />
    //     </ExchangeHandler>
    // );
    return (
        <HeaderWrapper className={clsx(className)}>
            <Container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <FlexBox
                    alignItems="center"
                    mr={2}
                    minWidth="170px"
                    sx={{
                        display: {
                            xs: "none",
                            md: "flex",
                        },
                    }}
                >
                    <Link to="/">
                        <Image height={44} src={BlackLogo} alt="logo" />
                    </Link>

                    {isFixed && (
                        <CategoryMenu>
                            <FlexBox
                                color="grey.600"
                                alignItems="center"
                                ml={2}
                            >
                                <BazarButton color="inherit">
                                    <Category
                                        fontSize="small"
                                        color="inherit"
                                    />
                                    <KeyboardArrowDown
                                        fontSize="small"
                                        color="inherit"
                                    />
                                </BazarButton>
                            </FlexBox>
                        </CategoryMenu>
                    )}
                </FlexBox>

                <FlexBox justifyContent="center" flex="1 1 0">
                    <SearchBox />
                </FlexBox>

                <FlexBox alignItems="center">
                    {cartHandle}
                    {/* <ExchangeRateMenu>{exchangeHandler}</ExchangeRateMenu> */}
                </FlexBox>

                <Drawer
                    open={sidenavOpen}
                    anchor="right"
                    onClose={toggleSidenav}
                >
                    <MiniCart />
                </Drawer>
            </Container>
        </HeaderWrapper>
    );
};

export default Header;
