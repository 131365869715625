/* eslint-disable react-hooks/exhaustive-deps */

import FlexBox from "containers/FlexBox";
import { H5, H6, Span } from "containers/Typography";
import {
    Card,
    Checkbox,
    Divider,
    FormControlLabel,
    Skeleton,
    TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { deleteQueryParam, updateQuery } from "utils/utils";
import { useLocation } from "react-router-dom";
import { useCategories } from "hooks/useCategories";
import { useQuery } from "hooks/useQuery";
import FilterMenuItem from "components/Categories/FilterMenuItem";

const ProductFilterCard = ({setLocation}) => {
    const query = useQuery();
    // const navigate = useNavigate();

    const { pathname, search } = useLocation();

    let { categories, loadingCategories } = useCategories();
    const [otherCheck, setOtherCheck] = useState({
        is_new: false,
        with_discount: false,
        best_prices: false,
        best_selling: false,
    });
    const [priceFilter, setPriceFilter] = useState({
        low: "",
        high: "",
    });

    const handleLowPriceFilterChange = (e) => {
        setPriceFilter({ ...priceFilter, low: e.target.value });
    };

    const handleLowPriceFilterSetOnKeyDown = (e) => {
        if (e.key === "Enter") {
            setLocation(
                updateQuery(pathname, search, "price_low", priceFilter.low)
            );
        }
    };

    const handleLowPriceFilterSetOnBlur = (e) => {
        setLocation(
            updateQuery(pathname, search, "price_low", priceFilter.low)
        );
    };

    const handleHighPriceFilterSetOnKeyDown = (e) => {
        if (e.key === "Enter") {
            setLocation(
                updateQuery(pathname, search, "price_high", priceFilter.high)
            );
        }
    };

    const handleHighPriceFilterSetOnBlur = (e) => {
        setLocation(
            updateQuery(pathname, search, "price_high", priceFilter.high)
        );
    };

    const handleHighPriceFilterChange = (e) => {
        setPriceFilter({ ...priceFilter, high: e.target.value });
    };

    const handleCheck = (key) => {
        const newQuery = otherCheck[key]
            ? deleteQueryParam(pathname, search, key)
            : updateQuery(pathname, search, key, "True");
        setLocation(newQuery);
        setOtherCheck({
            ...otherCheck,
            [key]: !otherCheck[key],
        });
    };

    const otherOptions = [
        {
            option: "Nuevos",
            checked: otherCheck.is_new,
            onChange: () => handleCheck("is_new"),
        },
        {
            option: "Ofertas",
            checked: otherCheck.with_discount,
            onChange: () => handleCheck("with_discount"),
        },
        {
            option: "Mejores vendidos",
            checked: otherCheck.best_selling,
            onChange: () => handleCheck("best_selling"),
        },
        {
            option: "Mejores precios",
            checked: otherCheck.best_prices,
            onChange: () => handleCheck("best_prices"),
        },
    ];

    useEffect(() => {
        let is_new = query.get("is_new") || null;
        let with_discount = query.get("with_discount") || null;
        let best_selling = query.get("best_selling") || null;
        let best_prices = query.get("best_prices") || null;
        let price_low = query.get("price_low") || "";
        let price_high = query.get("price_high") || "";
        if (is_new || with_discount || best_selling || best_prices) {
            setOtherCheck((prev) => {
                return {
                    ...prev,
                    is_new: is_new === "True" ? true : false,
                    with_discount: with_discount === "True" ? true : false,
                    best_prices: best_prices === "True" ? true : false,
                    best_selling: best_selling === "True" ? true : false,
                };
            });
        } else {
            setOtherCheck((prev) => {
                return {
                    ...prev,
                    is_new: is_new === false,
                    with_discount: with_discount === false,
                    best_selling: best_selling === false,
                    best_prices: best_prices === false,
                };
            });
        }
        if (price_low || price_high) {
            setPriceFilter({
                low: price_low,
                high: price_high,
            });
        } else {
            setPriceFilter({
                low: "",
                high: "",
            });
        }
    }, [query]);

    const handleLocationChange = (newLocation) => {
        setLocation(newLocation)
    }


    return (
        <Card
            sx={{
                p: "18px 27px",
                overflow: "auto",
            }}
            elevation={1}
        >
            <H6 mb={1.25}>Catálogos</H6>

            {!loadingCategories && categories
                ? categories.map(({ name, slug, subcategories }, ind) => (
                      <FilterMenuItem
                          name={name}
                          slug={slug}
                          subcategories={subcategories}
                          level={0}
                          key={ind}
                          afterClick={handleLocationChange}
                      />
                  ))
                : Array.apply(null, Array(4))
                      .map(function () {
                          return null;
                      })
                      .map((item, ind) => (
                          <Skeleton variant="text" key={ind} />
                      ))}

            <Divider
                sx={{
                    mt: "18px",
                    mb: "24px",
                }}
            />

            <H6 mb={2}>Rango de precios</H6>
            <FlexBox justifyContent="space-between" alignItems="center">
                <TextField
                    placeholder="0"
                    type="number"
                    size="small"
                    fullWidth
                    value={priceFilter.low}
                    onChange={handleLowPriceFilterChange}
                    onBlur={handleLowPriceFilterSetOnBlur}
                    onKeyDown={handleLowPriceFilterSetOnKeyDown}
                />
                <H5 color="grey.600" px={1}>
                    -
                </H5>
                <TextField
                    placeholder="250"
                    type="number"
                    size="small"
                    fullWidth
                    value={priceFilter.high}
                    onChange={handleHighPriceFilterChange}
                    onBlur={handleHighPriceFilterSetOnBlur}
                    onKeyDown={handleHighPriceFilterSetOnKeyDown}
                />
            </FlexBox>

            <Divider
                sx={{
                    mt: "18px",
                    mb: "24px",
                }}
            />

            {otherOptions.map(({ option, checked, onChange }, ind) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={onChange}
                            size="small"
                            color="secondary"
                        />
                    }
                    label={<Span color="inherit">{option}</Span>}
                    sx={{
                        display: "flex",
                    }}
                    key={ind}
                />
            ))}
        </Card>
    );
};

export default ProductFilterCard;
