/* eslint-disable react-hooks/exhaustive-deps */

import Carousel from "components/Carousel/Carousel";
import SectionMiddleCreator from "containers/SectionMiddleCreator";
import useWindowSize from "hooks/useWindowSize";
import React, { useCallback, useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { BrandsCarousel } from "api/resources/carousels";
import { useToast } from "hooks/useToast";
import { BrandSkeleton } from "components/ProductsCards/Skeletons/BrandSkeleton";

const ImgBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    height: "100%",
    img: {
        heignt: "225px",
        width: "225px",
        objectFit: "cover",
    },
}));
export const Partners = () => {
    const [visibleSlides, setVisibleSlides] = useState(4);
    const width = useWindowSize();
    const [carouselData, setCarouselData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { notify } = useToast();
    const setData = useCallback(async () => {
        setLoading(true);
        const carousel = await BrandsCarousel.getAll();
        if (carousel.data) {
            setCarouselData(carousel.data);
        } else if (carousel.error) {
            notify({
                message:
                    "Ocurrio un error obteniendo la data de nuestros aliados",
                type: "error",
            });
        }
        setLoading(false);
    }, []);
    useEffect(() => setData(), [setData]);
    useEffect(() => {
        if (width < 425) setVisibleSlides(1);
        else if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(3);
        else if (width < 1200) setVisibleSlides(4);
        else setVisibleSlides(5);
    }, [width]);
    return (
        <SectionMiddleCreator
            title="Nuestros aliados"
            subtitle="Catálogo de nuestras marcas favoritas en el 2022"
        >
            {loading || carouselData.length >= 1 ? (
                <>
                    {!loading ? (
                        <Carousel
                            totalSlides={carouselData.length}
                            visibleSlides={
                                carouselData.length > visibleSlides
                                    ? visibleSlides
                                    : carouselData.length
                            }
                            showArrow={carouselData.length > visibleSlides}
                        >
                            {carouselData.map((partner, index) => (
                                <ImgBox key={index}>
                                    <img
                                        src={partner.image}
                                        alt={partner.brand.name + " logo"}
                                    />
                                </ImgBox>
                            ))}
                        </Carousel>
                    ) : (
                        <Carousel
                            totalSlides={visibleSlides}
                            visibleSlides={visibleSlides}
                            showArrow={false}
                        >
                            {Array.apply(null, Array(visibleSlides))
                                .map(function () {return null})
                                .map((item, ind) => (
                                    <Box py={0.5} key={ind}>
                                        <BrandSkeleton />
                                    </Box>
                                ))}
                        </Carousel>
                    )}
                </>
            ) : null}
        </SectionMiddleCreator>
    );
};
