export const section1 = {
    title: "Productos"
}

export const section2 = {
    title: "Catálogos"
}

export const section3 = {
    title: "Mejor Punteados"
}

export const section4 = {
    title: "Nuevos"
}