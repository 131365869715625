import { Container, styled } from "@mui/material";
import React, {useState, useEffect} from "react";
import BannerImg from "assets/images/banner-home.png";
import useWindowSize from "hooks/useWindowSize";
import {MainBanner} from "api/resources/banner"
import {SELECTED_ENVIRONTMENT} from 'api/config';

const Wrapper = styled(Container)(({ theme }) => ({
    marginBottom: "2rem",
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        display: "block",
    },
}));

export const Banner = () => {
    const width = useWindowSize();
    const [bannerImage, setBannerImage] = useState(BannerImg)
    const getBanner = async () => {
        const { data, error } = await MainBanner.get();
        if (!error) {
            const { image } = data;
            setBannerImage(SELECTED_ENVIRONTMENT.resources + image)
        }
    };

    useEffect(
        () => {
            getBanner()
        }, []
    )
    return (
        <Wrapper
            sx={{
                width: "100%",
                height: `${width}/4`,
            }}
        >
            <img src={bannerImage} alt="Banner en vista principal" />
        </Wrapper>
    );
};
