import { findIndex, remove } from "lodash";
import { Cart, CartProduct, Product } from "interfaces/interfaces";
import { DispatchAction } from "contexts/createDataContext";

const clearCart = (dispatch: DispatchAction<Cart>) => () => {
    window.localStorage.removeItem("cart");
    dispatch({ type: "clear_cart" });
};
const getCart = (dispatch: DispatchAction<Cart>) => () => {
    const cartStr = window.localStorage.getItem("cart");

    if (cartStr) {
        const cart: Cart = JSON.parse(cartStr);
        dispatch({ type: "get_cart", payload: { ...cart } });
    } else {
        const cart: Cart = { products: [], subtotal: 0, total: 0 };
        dispatch({ type: "get_cart", payload: { ...cart } });
    }
};

const addProduct =
    (dispatch: DispatchAction<Cart>) =>
    (products: CartProduct[], product: Product) => {
        let newProducts = [...products];
        let total = 0;
        let subtotal = 0;
        const productIndex = findIndex(
            products,
            (item) => item.id === product.id
        );

        if (productIndex === -1) {
            newProducts.push({
                ...product,
                quantity: 1,
                subtotal: product.stockrecords[0].price,
                total: product.stockrecords[0].price,
            });
        } else {
            const quantity = newProducts[productIndex].quantity + 1;
            const price = newProducts[productIndex].stockrecords[0].price;
            const subtotal = price * quantity;
            const total = price * quantity;
            newProducts[productIndex] = {
                ...newProducts[productIndex],
                quantity,
                subtotal,
                total,
            };
        }
        newProducts.forEach(
            ({ quantity, stockrecords, discount_percentage }) => {
                const discount = !!parseFloat(discount_percentage)
                    ? parseFloat(discount_percentage)
                    : 0;
                subtotal += quantity * stockrecords[0].price;
                total += parseFloat(
                    (
                        quantity * stockrecords[0].price -
                        (quantity * stockrecords[0].price * discount) / 100
                    ).toFixed(2)
                );
            }
        );

        const cart = JSON.stringify({ products: newProducts, subtotal, total });
        window.localStorage.setItem("cart", cart);

        dispatch({
            type: "add_product",
            payload: { products: newProducts, subtotal, total },
        });
    };

const subProduct =
    (dispatch: DispatchAction<Cart>) =>
    (products: CartProduct[], product: Product) => {
        let newProducts = [...products];
        let total = 0;
        let subtotal = 0;
        const productIndex = findIndex(
            products,
            (item) => item.id === product.id
        );

        newProducts[productIndex] = {
            ...newProducts[productIndex],
            quantity: newProducts[productIndex].quantity - 1,
        };

        products.forEach(({ quantity, stockrecords, discount_percentage }) => {
            const discount = !!parseFloat(discount_percentage)
                ? parseFloat(discount_percentage)
                : 0;
            subtotal += quantity * stockrecords[0].price;
            total += parseFloat(
                (
                    quantity * stockrecords[0].price -
                    (quantity * stockrecords[0].price * discount) / 100
                ).toFixed(2)
            );
        });

        const cart = JSON.stringify({ products: newProducts, subtotal, total });
        window.localStorage.setItem("cart", cart);

        dispatch({
            type: "sub_product",
            payload: { products: newProducts, subtotal, total },
        });
    };

const removeProduct =
    (dispatch: DispatchAction<Cart>) =>
    (products: CartProduct[], product: Product) => {
        let total = 0;
        let subtotal = 0;
        remove(products, ({ id }) => id === product.id);
        products.forEach(({ quantity, stockrecords, discount_percentage }) => {
            const discount = !!parseFloat(discount_percentage)
                ? parseFloat(discount_percentage)
                : 0;
            subtotal += quantity * stockrecords[0].price;
            total += parseFloat(
                (
                    quantity * stockrecords[0].price -
                    (quantity * stockrecords[0].price * discount) / 100
                ).toFixed(2)
            );
        });

        const cart = JSON.stringify({ products, subtotal, total });
        window.localStorage.setItem("cart", cart);

        dispatch({
            type: "remove_product",
            payload: { products, subtotal, total },
        });
    };

export const actions = {
    clearCart,
    addProduct,
    subProduct,
    removeProduct,
    getCart,
};
