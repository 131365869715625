// const LOCAL_URL = "http://localhost:8000/api";
// const LOCAL_RESOURCES_URL = "http://localhost:8000";
// const LOCAL_DOMAIN= "localhost:3000"
// const LOCAL = {
//     url: LOCAL_URL,
//     resources: LOCAL_RESOURCES_URL,
//     domain: LOCAL_DOMAIN,
//      client: LOCAL_DOMAIN
// }

// import { PRODUCTION } from "settings/constant";

const STAGING_URL = "https://api-staging.mauer.ubicutus.com/api"
const STAGING_RESOURCES_URL = ""
const STAGING_DOMAIN = "api-staging.mauer.ubicutus.com"
const STAGING_CLIENT = "staging.mauer.ubicutus.com"
const STAGING = {
    url: STAGING_URL,
    resources: STAGING_RESOURCES_URL,
    domain: STAGING_DOMAIN,
    client: STAGING_CLIENT
};

const PRODUCTION_URL = "https://api.mauer-home.com/api"
const PRODUCTION_RESOURCES_URL = ""
const PRODUCTION_DOMAIN = "api.mauer-home.com"
const PRODUCTION_CLIENT = "mauer-home.com"
const PRODUCTION = {
    url: PRODUCTION_URL,
    resources: PRODUCTION_RESOURCES_URL,
    domain: PRODUCTION_DOMAIN,
    client: PRODUCTION_CLIENT
};

export const SELECTED_ENVIRONTMENT = PRODUCTION;
