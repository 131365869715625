import React from "react";
import ProductCard9 from "../ProductsCards/ProductCard9";
import ProductCard9Skeleton from "components/ProductsCards/Skeletons/ProductCard9Skeleton";
import ProductCard2 from "components/ProductsCards/ProductCard2";
import useWindowSize from "hooks/useWindowSize";
import { Box } from "@mui/material";

const ProductCard9List = ({ products, loading }) => {
    const width = useWindowSize();
    return (
        <div>
            {!loading
                ? products.slice(0, products.length).map((item, ind) => {
                      if (width > 600) {
                          return <ProductCard9 key={item.id} product={item} />;
                      } else {
                          return (
                              <Box marginBottom={"5px"} key={item.id}>
                                  <ProductCard2 product={item} />;
                              </Box>
                          );
                      }
                  })
                : Array.apply(null, Array(4))
                      .map(function () {
                          return null;
                      })
                      .map((item, ind) => <ProductCard9Skeleton />)}
        </div>
    );
};

export default ProductCard9List;
