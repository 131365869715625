import { axiosMethod, DOMAIN, URL } from "./utils";

export const checkout = (resource, data, uuid) => {

    return axiosMethod({
        method: "post",
        url: `${URL}/${resource}/`,
        data,
        headers: {
            "Session-Id": `SID:ANON:${DOMAIN}:${uuid}`
        }
    });
};