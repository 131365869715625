import { Categories } from "api/resources/categories";
import { DispatchAction } from "contexts/createDataContext";
import { Category } from "interfaces/context/Categories";

const clearCategories = (dispatch: DispatchAction<Category>) => () =>
    dispatch({ type: "clear_categories" });

const getCategories = (dispatch: DispatchAction<Category>) => async (errorHandler: () => void) => {
    let categoriesList: any[];
    Categories.getAll().then((res) => {
        if (res.error) {
            errorHandler()
        } else {
            categoriesList = res.data;
            dispatch({ type: "get_categories", payload: { categoriesList } });
        }
    });
};
const actions = { clearCategories, getCategories };

export { actions };
