import FlexBox from "containers/FlexBox";
import { Span } from "containers/Typography";
import { Box, Divider, Typography } from "@mui/material";
import { Context as CartContext } from "contexts/Cart/CartContext";
import React, { useContext } from "react";
import { reduceTitle } from "utils/utils";
import { NoIva } from "components/NoIva";
import { useExchangeRates } from "hooks/useExchangeRates";
import { exchangeRatesList } from "data/ExchangeRates";

const CheckoutSummary2 = () => {
    const {
        state: { products, total, subtotal },
    } = useContext(CartContext);
    const { id: exchangeId, exchange_rate } = useExchangeRates();
    const { symbol } = exchangeRatesList[exchangeId];
    const totalsDiscount =
        total !== 0 ? ((subtotal / total) * 100 - 100).toFixed(2) : 0;
    return (
        <Box>
            <Typography color="secondary.900" fontWeight="700" mb={3}>
                Orden
            </Typography>
            {products.length === 0 && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    mb={3}
                >
                    <Span sx={{ textAlign: "center" }}>
                        Tu carrito esta vacío. Comienza a comprar ahora.
                    </Span>
                </Box>
            )}
            {products.map((item) => (
                <FlexBox
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                    key={item.id}
                >
                    <Typography>
                        <Span fontWeight="700" fontSize="14px">
                            {item.quantity}
                        </Span>{" "}
                        x {reduceTitle(item.title, 40)}
                    </Typography>
                    {!!!parseFloat(item?.discount_percentage) ? (
                        <Typography>
                            {`${symbol} `}
                            {(
                                item.quantity *
                                item.stockrecords[0].price *
                                exchange_rate
                            ).toFixed(2)}
                        </Typography>
                    ) : (
                        <Typography>
                            <del>
                                {`${symbol} `}
                                {(
                                    item.quantity *
                                    item.stockrecords[0].price *
                                    exchange_rate
                                ).toFixed(2)}
                            </del>{" "}
                            {`${symbol} `}
                            {(
                                (item.quantity * item.stockrecords[0].price -
                                    (item.quantity *
                                        item.stockrecords[0].price *
                                        parseFloat(item?.discount_percentage)) /
                                        100) *
                                exchange_rate
                            ).toFixed(2)}
                        </Typography>
                    )}
                </FlexBox>
            ))}
            {total !== subtotal && (
                <>
                    <Divider
                        sx={{
                            borderColor: "grey.300",
                            mb: "0.5rem",
                        }}
                    />

                    <FlexBox
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                    >
                        <Typography color="grey.600">Subtotal:</Typography>
                        <Typography fontWeight="700">
                            {`${symbol} `}
                            {(subtotal * exchange_rate).toFixed(2)}
                        </Typography>
                    </FlexBox>

                    {totalsDiscount !== 0 && totalsDiscount !== "0.00" && (
                        <FlexBox
                            justifyContent="space-between"
                            alignItems="center"
                            mb="0.5rem"
                        >
                            <Typography color="grey.600">Descuento</Typography>
                            <Typography fontWeight="700">
                                Aprox {Math.round(parseFloat(totalsDiscount))}%
                            </Typography>
                        </FlexBox>
                    )}

                    {/* <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
        <Typography color="grey.600">Impuestox:</Typography>
        <Typography fontWeight="700">${(40).toFixed(2)}</Typography>
      </FlexBox> */}
                </>
            )}

            <Divider
                sx={{
                    borderColor: "grey.300",
                    mb: "0.5rem",
                }}
            />

            <FlexBox
                fontWeight="800"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
            >
                <Typography>Total:</Typography>
                <Typography fontWeight="800">
                    {`${symbol} `}
                    {(total * exchange_rate).toFixed(2)}
                </Typography>
            </FlexBox>
            <Box
                mb={1}
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <NoIva />
            </Box>
        </Box>
    );
};

export default CheckoutSummary2;
