// import AppStore from "components/AppStore";
import BazarIconButton from "components/BazarIconButton";
import Image from "components/BazarImage";
import { Paragraph } from "../Typography";
import { Box, Container, Grid } from "@mui/material";
import React from "react";

import WhiteLogo from "assets/images/white-logo.png";
import { Link } from "react-router-dom";
import { business_data } from "data/FieldsContent/business-data";
import { askMessage } from "data/FieldsContent/Footer";
import { Order } from "api/resources/orders";
import { useToast } from "hooks/useToast";
import { isIOS, navigateToUrl, openInNewTab, whatsappURI } from "utils/utils";
import BazarImage from "components/BazarImage";
import byUbicutus from "assets/images/byUbicutus.png";
// import _ from "lodash";

const Footer = () => {
    const { notify } = useToast();
    const askToWhatsapp = async () => {
        const { data, error } = await Order.whatsapp();
        if (!!!error) {
            const { phone } = data;
            if (isIOS()) {
                navigateToUrl(whatsappURI({ phone, text: askMessage }));
            } else {
                openInNewTab(whatsappURI({ phone, text: askMessage }));
            }
        } else {
            notify(
                "Ocurrio un error obteniendo el número de contacto de la empresa"
            );
        }
    };
    return (
        <footer>
            <Box bgcolor="secondary.main">
                <Container
                    sx={{
                        p: "1rem",
                        color: "white",
                    }}
                >
                    <Box py={5} overflow="hidden">
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: "space-between" }}
                        >
                            <Grid item sm={6} xs={12}>
                                <Link to="/">
                                    <Image
                                        mb={2.5}
                                        height={40}
                                        src={WhiteLogo}
                                        alt="logo"
                                    />
                                </Link>

                                <Box py={0.6} color="white">
                                    {business_data.contact.address}
                                </Box>
                                <Box py={0.6} color="white">
                                    Correo:{" "}
                                    <a
                                        href={`mailto:${business_data.contact.email}`}
                                    >
                                        {business_data.contact.email}
                                    </a>
                                </Box>
                                <Box py={0.6} color="white">
                                    Teléfono:{" "}
                                    <span
                                        onClick={askToWhatsapp}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {business_data.contact.number}
                                    </span>
                                </Box>
                                <Box
                                    py={0.6}
                                    mb={2}
                                    color="white"
                                    onClick={askToWhatsapp}
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <BazarIconButton
                                        bgcolor="rgba(0,0,0,0.2)"
                                        fontSize="12px"
                                        padding="10px"
                                    >
                                        <business_data.contact.instagram.icon fontSize="inherit" />
                                    </BazarIconButton>
                                    <a
                                        href={
                                            business_data.contact.instagram.to
                                        }
                                        target="_blank"
                                        rel="noreferrer noopenner"
                                    >
                                        {business_data.contact.instagram.label}
                                    </a>
                                </Box>

                                {/* <AppStore /> */}
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <Box
                                    fontSize="25px"
                                    fontWeight="600"
                                    mb={2.5}
                                    lineHeight="1"
                                    color="white"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        flexDirection: "column",
                                        height: "100%",
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flexDirection="column"
                                        mb={2}
                                    >
                                        <BazarImage
                                            src={byUbicutus}
                                            alt="By ubicutus"
                                            sx={{
                                                objectFit: "cover",
                                                width: "150px",
                                            }}
                                        />
                                        <Paragraph>
                                            Desarrollado por{" "}
                                            <b>Ubicutus Apps</b>
                                        </Paragraph>
                                        <a href="mailto:contact@ubicutus.com">
                                            <Paragraph>
                                                <b>Contáctanos</b>
                                            </Paragraph>
                                        </a>
                                    </Box>
                                </Box>
                            </Grid>

                            {/*<Grid item lg={3} md={6} sm={6} xs={12}>
                                <Box
                                    fontSize="25px"
                                    fontWeight="600"
                                    mb={2.5}
                                    lineHeight="1"
                                    color="white"
                                >
                                    Contáctanos
                                </Box>

                                <FlexBox className="flex" mx={-0.625}>
                                    {business_data.social_media.map(
                                        (item, ind) => (
                                            <Link
                                                to={item.link}
                                                target="_blank"
                                                rel="noreferrer noopenner"
                                                key={ind}
                                            >
                                                <BazarIconButton
                                                    m={0.5}
                                                    bgcolor="rgba(0,0,0,0.2)"
                                                    fontSize="12px"
                                                    padding="10px"
                                                >
                                                    <item.icon fontSize="inherit" />
                                                </BazarIconButton>
                                            </Link>
                                        )
                                    )}
                                </FlexBox>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </footer>
    );
};

export default Footer;
