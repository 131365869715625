import { useState } from "react";

type StorageKeys = "basket" | "addresses" | "checkout" | "cart" | "uuid" | "order" | "exchange-rate";

export const useLocalStorage = <ValueType>(
    key: StorageKeys,
    defaultValue: ValueType
) => {
    const [storedValue, setStoredValue] = useState<ValueType>(() => {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                return defaultValue;
            }
        } catch (error) {
            return defaultValue;
        }
    });

    const setValue = (newValue: ValueType) => {
        try {
            localStorage.setItem(key, JSON.stringify(newValue));
        } catch (error) {
            // console.log(error);
        }
        setStoredValue(newValue);
    };

    const removeKey = () => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            // console.log(error);
        }
        setStoredValue(defaultValue);
    };
    return [ storedValue, setValue, removeKey ];
};
