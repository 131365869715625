export const linkGroup1 = {
    title: "Sobre nosotros",
    links: [
        { label: "Nuestras tiendas", link: "/" },
        { label: "Términos y condiciones", link: "/" },
        { label: "Politica de privacidad", link: "/" },
    ],
};

export const linkGroup2 = {
    title: "Catálogos",
    links: [
        { label: "Ropa", link: "/" },
        { label: "Calzado", link: "/" },
        { label: "Tecnólogia", link: "/" },
    ],
};

export const askMessage = "Saludos, tengo una duda acerca de sus productos."