
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Box, Button, Card, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useRef, useState } from "react"; // styled components
import { useNavigate } from "react-router-dom";
// also used in the GrocerySearchBox component

export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6,
})); // also used in the GrocerySearchBox component

export const SearchResultCard = styled(Card)(() => ({
    position: "absolute",
    top: "100%",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    width: "100%",
    zIndex: 99,
}));

const SearchBox = () => {
    const parentRef = useRef();
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        const {
            target: { value },
        } = e;
        setSearch(value);
    };

    const onEnterPress = (e) => {
        if (e.key === "Enter") {
            navigateToSearch()
        }
    };

    const navigateToSearch = () => {
        navigate({
            pathname: "/products",
            search: `?title=${search}`,
        });
    }

    return (
        <Box
            position="relative"
            flex="1 1 0"
            maxWidth="670px"
            mx="auto"
            {...{
                ref: parentRef,
            }}
        >
            <TextField
                variant="outlined"
                placeholder="Buscar..."
                fullWidth
                onChange={handleSearch}
                onKeyDown={onEnterPress}
                value={search}
                InputProps={{
                    sx: {
                        height: 44,

                        borderRadius: 300,
                        paddingRight: 0,
                        color: "grey.700",
                        overflow: "hidden",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                        },
                    },
                    startAdornment: <SearchOutlinedIcon fontSize="small" />,
                    endAdornment: (
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={navigateToSearch}
                        >
                            Buscar
                        </Button>
                    ),
                }}
                
            />
        </Box>
    );
};

export default SearchBox;
