import { Box, Container } from "@mui/material";
import React from "react";
import CategorySectionHeader from "./CategorySectionHeader";

const CategorySectionCreator = ({ icon, seeMoreLink, title, children, subtitle }) => {
    return (
        <>
            {children ? (
                <Box mb={5}>
                    <Container
                        sx={{
                            pb: "1rem",
                        }}
                    >
                        {title && (
                            <CategorySectionHeader
                                title={title}
                                seeMoreLink={seeMoreLink}
                                icon={icon}
                                subtitle={subtitle}
                            />
                        )}

                        {children}
                    </Container>
                </Box>
            ) : null}
        </>
    );
};

export default CategorySectionCreator;
