import { axiosMethod, DOMAIN, URL } from "./utils";

export const addProduct = (resource, productUrl, quantity) => {
    return axiosMethod({
        method: "post",
        url: `${URL}/${resource}/add-product/`,
        data: {
            url: productUrl,
            quantity,
        },
    });
};

export const deleteProduct = (resource, productId) => {
    return axiosMethod({
        method: "delete",
        url: `${URL}/${resource}/add-product/`,
        data: {
            id: productId,
        },
    });
};

export const init = (resource, uuid) => {
    return axiosMethod({
        method: "get",
        url: `${URL}/${resource}/`,
        headers: {
            "Session-Id": `SID:ANON:${DOMAIN}:${uuid}`
        }
    });
};

export const addMultiple = (resource, productList, uuid) => {
    return axiosMethod({
        method: "post",
        url: `${URL}/${resource}/add-multiple/`,
        data: productList,
        headers: {
            "Session-Id": `SID:ANON:${DOMAIN}:${uuid}`
        }
    })
}
