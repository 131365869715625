import React from "react";
import CategorySectionCreator from "../CategorySectionCreator";
import { Link } from "react-router-dom";
import { section2 } from "data/FieldsContent/HomeFields";
import { useCategories } from "hooks/useCategories";
import { Box, Chip, useTheme } from "@mui/material";
import FlexBox from "containers/FlexBox";
import { strFirstLetterUpperCase } from "utils/utils";
import Category from "icons/Category";

const TopCategories = () => {
    const theme = useTheme()
    const { categories, loadingCategories } = useCategories();

    return (
        <>
            {loadingCategories || categories ? (
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <CategorySectionCreator title={section2.title}>
                        {categories ? (
                            <FlexBox
                                flexWrap="wrap"
                                justifyContent="space-around"
                                alignItems="space-around"
                                sx={{
                                    height: "fit-content",
                                    gap: 1,
                                }}
                            >
                                {categories.length > 0 &&
                                    categories.map(
                                        ({ name, image, slug }, ind) => (
                                            <Link
                                                key={ind}
                                                to={`/products?category=${slug}`}
                                            >
                                                <Chip
                                                    icon={<Category />}
                                                    sx={{
                                                        height: 50,
                                                        width: 110,
                                                        borderColor: theme.palette.text.primary,
                                                        borderStyle: "solid",
                                                        borderWidth: .5,
                                                        boxShadow: ".5px .5px 2px 0px rgba(0,0,0,0.75)",
                                                        backgroundColor: theme.palette.background.paper
                                                    }}
                                                    
                                                    label={strFirstLetterUpperCase(
                                                        name
                                                    )}
                                                    clickable
                                                />
                                            </Link>
                                        )
                                    )}
                            </FlexBox>
                        ) : null}
                    </CategorySectionCreator>
                </Box>
            ) : null}
        </>
    );
};

export default TopCategories;
