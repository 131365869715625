import React, { useReducer, FunctionComponent, Reducer, Dispatch, createContext } from 'react';

interface Action<S> {
  type: string;
  payload?: S;
}


export type DispatchAction<S> = Dispatch<Action<S>>

// this is where I am getting tricky
type BoundActions<T, S> = {
  [K in keyof T]: T[K] extends (d: DispatchAction<S>) => infer R ? R : never
}

type ContextValue<T, S> = {
  state: S;
} & BoundActions<T, S>

const createDataContext = <T extends {}, S extends {}>(reducer: Reducer<S, Action<S>>, actions: T, defaultValue: S) => {
  // context needs a defaultValue
  const Context = createContext({state: defaultValue} as ContextValue<T, S>);

  // type of children is known by assigning the type FunctionComponent to Provider
  const Provider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {} as BoundActions<T,S>;
    for (let key in actions) {
      // @ts-ignore - I don't want to make a confusing mess so just ignore this
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
}

export default createDataContext