
import { Box, styled, Typography } from "@mui/material";
import { useToast } from "hooks/useToast";
import React, { useEffect } from "react";

const ToastContainer = styled(Box)(({ theme }) => ({
    position: "fixed",
    fontSize: 14,
    boxSizing: "border-box",
    bottom: 12,
    right: 12,
    zIndex: 10000,
    transition: "transform .6s ease-in",
    animation: "toast-in-left .7s",
    ".success": {
        backgroundColor: theme.palette.success.main,
        color: "white",
    },
    ".warning": {
        backgroundColor: theme.palette.warning.main,
        color: "black",
    },
    ".info": {
        backgroundColor: theme.palette.info.main,
        color: "white",
    },
    ".error": {
        backgroundColor: theme.palette.error.main,
        color: "white",
    },
    ".toast:hover": {
        boxShadow: "0 0 12px #fff",
        opacity: 1,
        cursor: "pointer",
    },

}));

const ToastWrapper = styled(Box)(({ theme }) => ({
    transition: ".3s ease",
    position: "relative",
    pointerEvents: "auto",
    overflow: "hidden",
    margin: "15px 6px",
    padding: "15px",
    minWidth: "400px",
    maxHeight: "100px",
    borderRadius: "3px",
    boxShadow: "0 0 10px #999",
    opacity: ".9",
    backgroundPosition: "15px",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.between("sm","md")]: {
        minWidth: "50vw"
    },
    [theme.breakpoints.down("sm")]: {
        minWidth: "90vw"
    }
}));

const Title = styled(Typography)(({ theme }) => ({}));

const Message = styled(Typography)(({ theme }) => ({}));

export const Toast = () => {
    const { toastList, closeToast } = useToast();

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastList.length) {       
                closeToast(toastList[0].id);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [toastList, closeToast]);

    return (
        <ToastContainer>
            {toastList.map(({ title, type, message, id }) => (
                <ToastWrapper
                    onClick={() => closeToast(id)}
                    key={id}
                    className={`toast ${type}`}
                >
                    <Title variant="h6">{title}</Title>
                    <Message variant="body1">{message}</Message>
                </ToastWrapper>
            ))}
        </ToastContainer>
    );
};
