import { Box, styled } from '@mui/material'
import React from 'react'
const Wrapper = styled(Box)({
  backgroundColor: "black",
  position: "absolute",
  top: 20,
  left: 0,
  zIndex: 15,
  color: "white",
  height: "20px",
  width: "75px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: 10,
})
export const OfferBadge = ({discount}) => {
  return (
    <Wrapper>{discount}% menos</Wrapper>
  )
}
