import { Cart } from "interfaces/interfaces";
import { Action } from "interfaces/context/Cart";


export const initialState: Cart = {
    products: [],
    subtotal: 0,
    total: 0,
};
export const cartReducer = (state: Cart, {type, payload}: Action): Cart => {
    switch (type) {
        case "clear_cart":
            return {
                ...initialState,
            };
        case "get_cart":
            return {
                ...state,
                products: payload.products,
                subtotal: payload.subtotal,
                total: payload.total,
            };
        case "add_product":
            return {
                ...state,
                products: payload.products,
                subtotal: payload.subtotal,
                total: payload.total,
            };
        case "sub_product":
            return {
                ...state,
                products: payload.products,
                subtotal: payload.subtotal,
                total: payload.total,
            };
        case "remove_product":
            return {
                ...state,
                products: payload.products,
                subtotal: payload.subtotal,
                total: payload.total,
            };
        default:
            return state;
    }
};

