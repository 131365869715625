import { Basket } from "api/resources/basket";
import { Checkout } from "api/resources/checkout";
import { Order } from "api/resources/orders";
import { Context as CartContext } from "contexts/Cart/CartContext";
import { Product } from "interfaces/interfaces";
import { useContext } from "react";
import { v4 } from "uuid";
import { useLocalStorage } from "./useLocalStorage";
import { useToast } from "./useToast";

export const useCart = () => {
    const {
        state: { products },
        clearCart,
        addProduct,
        subProduct,
        removeProduct,
    } = useContext(CartContext);

    const { notify } = useToast();

    const [uuidInLocal, setUUIDInLocal] = useLocalStorage("uuid", null);

    const addToCart = (product: Product) => {
        addProduct(products, product);
    };

    const subInCart = (product: Product) => {
        subProduct(products, product);
    };

    const removeInCart = (product: Product) => {
        removeProduct(products, product);
    };

    const initCart = async () => {
        let uuid: string;
        if (!!!uuidInLocal || uuidInLocal === "null") {
            uuid = v4();
            setUUIDInLocal(uuid);
        } else {
            uuid = uuidInLocal;
        }

        const { data, error } = await Basket.init(uuid);
        if (!!!error) {
            return { basket: data.url, uuid };
        }
        return null;
    };

    const getCountOnCart = (id: any) => {
        return (
            products.filter((product) => product.id === id)[0]?.quantity || 0
        );
    };

    const addMultiple = async (uuid: string) => {
        let productList = [];
        products.map((product) => {
            productList.push({ url: product.url, quantity: product.quantity });
            return product;
        });
        const { error } = await Basket.addMultiple(productList, uuid);
        if (!!!error) {
            return true;
        }
        return false;
    };

    const checkoutCart = async (checkoutData, basket, uuid: string) => {
        const { data, error } = await Checkout.checkout(
            { ...checkoutData, basket },
            uuid
        );
        if (!!!error) {
            clearCart();
            return data.number;
        }
        return null;
    };

    const processWhatsapp = async (checkoutData) => {
        try {
            const { basket, uuid } = await initCart();
            if (basket) {
                const productsAdded = await addMultiple(uuid);
                if (productsAdded) {
                    const orderNumber = await checkoutCart(
                        checkoutData,
                        basket,
                        uuid
                    );
                    if (orderNumber) {
                        const { data, error } = await Order.whatsapp();
                        if (!!!error) {
                            return { ...data, orderNumber };
                        }
                        notify({
                            message:
                                "Ocurrió un problema procesando la información para Whatsapp",
                            type: "error",
                        });
                        return null;
                    } else {
                        notify({
                            message:
                                "Ocurrió un problema procesando el carrito",
                            type: "error",
                        });
                        return null;
                    }
                } else {
                    notify({
                        message:
                            "Ocurrió un problema agregando los productos al carrito",
                        type: "error",
                    });
                    return null;
                }
            } else {
                notify({
                    message: "Ocurrió un problema inicializando el carrito",
                    type: "error",
                });
                return null;
            }
        } catch (error) {
            notify({
                message: "Ocurrió un problema procesando el carrito",
                type: "error",
            });
            return null;
        }
    };

    return {
        checkoutCart,
        addToCart,
        removeInCart,
        subInCart,
        getCountOnCart,
        initCart,
        addMultiple,
        processWhatsapp,
    };
};
