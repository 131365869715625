import { Box, MenuItem, Skeleton, styled } from "@mui/material";
import React from "react";
import { useCategories } from "hooks/useCategories";
import { strFirstLetterUpperCase } from "utils/utils";
import { H4 } from "containers/Typography";
import { take } from "lodash";
import { Link } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    padding: "0.5rem",
    borderRadius: 4,
    backgroundColor: "rgba(255, 255, 255)",
    boxShadow: theme.shadows[2],
}));

const SkeletonStyles = styled(Skeleton)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.between("md", "xl")]: {
        height: 49,
    },
    [theme.breakpoints.down("md")]: {
        height: 40,
    },
}));

const Title = styled(H4)(({ theme }) => ({
    padding: "0.25rem 1rem",
    borderBottomWidth: "1px",
    borderBottomColor: theme.palette.primary,
    borderBottomStyle: "solid",

    [theme.breakpoints.between("md", "xl")]: {
        fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
        fontSize: 18,
    },
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
    "& .category-link": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        padding: "0px 1rem",

        minWidth: "278px",
        whiteSpace: "pre",
        transition: "all 250ms ease-in-out",

        [theme.breakpoints.between("md", "xl")]: {
            height: 40,
            "& .title": {
                paddingLeft: "0.75rem",
                flexGrow: 1,
                fontSize: 18,
            },
        },
        [theme.breakpoints.down("md")]: {
            height: 40,
            "& .title": {
                paddingLeft: "0.75rem",
                flexGrow: 1,
                fontSize: 14,
            },
        },
    },
}));

const CategoryItem = ({ href, title, ...rest }) => {
    return (
        <ItemWrapper>
            <Link to={href}>
                <MenuItem className="category-link">
                    <span className="title">{title}</span>
                </MenuItem>
            </Link>
        </ItemWrapper>
    );
};

const CategoryList = () => {
    const { categories, loadingCategories } = useCategories();
    
    return (
        <Wrapper>
            <Title>Catálogos</Title>
            {!loadingCategories
                ? categories &&
                  categories.map(({ name, image, slug }, ind) => {
                      return (
                          <CategoryItem
                              title={strFirstLetterUpperCase(name)}
                              href={`/products?category=${slug}&categoryLabel=${name}`}
                              key={ind}
                          ></CategoryItem>
                      );
                  })
                : Array.apply(null, Array(10))
                      .map(function () { return null})
                      .map((item, ind) => <SkeletonStyles key={ind} />)}
        </Wrapper>
    );
};

export default CategoryList;
