import { axiosMethod, DOMAIN, URL } from "./utils";

export const select = (resource, uuid, id) => {
    return axiosMethod({
        method: "get",
        url: `${URL}/${resource}/${id}/`,
        headers: {
            "Session-Id": `SID:ANON:${DOMAIN}:${uuid}`,
        },
    });
};
