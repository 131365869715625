export type Sizes = "Thumbnail" | "Small" | "Medium" | "Large"
export enum Size {
    Thumbnail = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
}
export interface Image {
    id: number;
    deleted_at: null;
    caption: string;
    display_order: number;
    date_created: Date;
    number: number;
    size: Size;
    original: string;
}
