import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, MenuItem, styled } from "@mui/material";
import React from "react"; // component props interface
import { Link } from "react-router-dom";

//styled component
const Wrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    "& .category-dropdown-link": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        padding: "0px 1rem",
        height: 40,
        minWidth: "278px",
        whiteSpace: "pre",
        transition: "all 250ms ease-in-out",
        "& .title": {
            paddingLeft: "0.75rem",
            flexGrow: 1,
        },
    },
    "&:hover": {
        "& > .category-dropdown-link": {
            color: theme.palette.primary.main,
            background: theme.palette.primary.light,
        },
        "& > .mega-menu": {
            display: "block",
        },
    },
}));

const CategoryMenuItem = ({
    href,
    title,
    caret,
    children,
    customClass,
    ...rest
}) => {
    return (
        <Wrapper>
            <Link to={href}>
                <MenuItem className={`category-dropdown-link`}>
                    <span className="title">{title}</span>
                    {caret && <ChevronRight fontSize="small" />}
                </MenuItem>
            </Link>
            {children}
        </Wrapper>
    );
};

CategoryMenuItem.defaultProps = {
    caret: false,
};
export default CategoryMenuItem;
