import { styled } from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";
import React from "react"; // component props interface

// styled component
const StyledLink = styled(Link)(({ theme, active_route }) => ({
  position: "relative",
  color: active_route === "active" ? theme.palette.primary.main : "inherit",
  transition: "color 150ms ease-in-out",
  "&:hover": {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const NavLink = ({ href, children, style, className, ...props }) => {
  
  return (
      <StyledLink
        active_route={true ? "active" : ""}
        className={clsx(className)}
        to={"/"}
        style={style}
        {...props}
      >
        {children}
      </StyledLink>
  );
};

export default NavLink;
