import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history"

const history = createBrowserHistory()
const app = document.getElementById("root")
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }

    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
        app
    )
}
