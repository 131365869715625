import FlexBox from "containers/FlexBox";
// import { Pagination } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";

const ProductCardListContainer = ({
    productsLength,
    children,
    pagination,
    handlePaginationChange,
    pageInterval,
}) => {
    const showMoreMax = Math.ceil(productsLength / pageInterval)
    // console.log("showMoreMax", showMoreMax);
    return (
        <div>
            {children}

            {/* <FlexBox
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
            >
                <Span color="grey.600">{`Mostrando ${pagination.low + 1} - ${
                    pagination.high > productsLength
                        ? productsLength
                        : pagination.high + 1
                } de ${productsLength} productos`}</Span>
                {productsLength > 0 && (
                    <Pagination
                        count={
                            productsLength > pageInterval
                                ? Math.ceil(productsLength / pageInterval)
                                : 1
                        }
                        page={pagination.page }
                        variant="outlined"
                        color="primary"
                        onChange={handlePaginationChange}
                    />
                )}
            </FlexBox> */}
            <FlexBox justifyContent="center" mt={4}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={pagination.page + 1 > showMoreMax}
                    onClick={() => handlePaginationChange(pagination.page + 1)}
                >
                    Mostrar más
                </Button>
            </FlexBox>
        </div>
    );
};

export default ProductCardListContainer;
